import React from 'react';

import { Collapse, Button, CardBody, Card } from 'reactstrap';

const Accordion = (props) => {
    return (
        <div className={`custom-acc-fq ${props.open ? 'active' : ''}`}>
            <div className={'cs-head'} onClick={props.toggle}> <h4>{props.heading}</h4></div>

            <Collapse isOpen={props.open}>
                {/* <Card> */}
                    <CardBody>
                        {props.children}
                    </CardBody>
                {/* </Card> */}
            </Collapse>
        </div>
    )
}

export default Accordion;