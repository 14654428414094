import React from 'react';
import { Row, Col, Container, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import MetaData from "Helpers/MetaData";
import { Header, Loader } from "Components";
import Select from "react-select";
import * as NC from "Constants/NetworkingConstants";
import WSManager from "Helpers/WsManager";
import _ from "lodash";
import { Link } from 'react-router-dom';
import Validation from "Helpers/validation";
import { notify } from "react-notify-toast";


class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            topic: "",
            comment: "",
            first_name: "",
            last_name: "",
            mobile: "",
            email: "",
            countryList: [],
            selectedCountry: "",
            isFormValid: false,
            posting:false
        }
    }


    onChangeHandler = e => {
        this.setState({
            [e.target.name]: e.target.value
        }, this.isFormValid)
    }

    selectChange = (e, key) => {
        this.setState({
            [key]: e
        }, this.isFormValid)
    }


    GetCountryData = () => {
        WSManager.Rest(NC.GET_ALL_COUNTRY, {}).then(responseJson => {
            if (responseJson.response_code === NC.successCode) {
                let tmpArray = [];
                _.map(responseJson.data.country_list, (item, idx) => {
                    tmpArray.push({
                        label: item.country_name,
                        value: item.master_country_id
                    });
                });
                this.setState({ countryList: tmpArray });
            }
        });
    }

    isFormValid = () => {
        let { topic, comment, first_name, last_name, mobile, email,countryList, selectedCountry, } = this.state;
        let isValid = true;

        if (topic == "" || topic.trim() == "") {
            isValid = false
        }
        else if (comment == "" || comment.trim() == "") {
            isValid = false
        }
        else if (first_name == "" || first_name.trim() == "" ) {
            isValid = false
        }
        else if (last_name == "" || last_name.trim() == "" ) {
            isValid = false
        }
        else if (selectedCountry == "") {
            isValid = false
        }
        else if (email != "" && Validation.validate("email", email) !== "success") {
            isValid = false
        }
        else if (mobile != "" && Validation.validate("phone_no", mobile) !== "success") {
            isValid = false
        }

        this.setState({isFormValid:isValid})
        return isValid;
    }


    componentDidMount() {
        this.GetCountryData()
    }


    onSubmit = (e) => {
        e.preventDefault();
        if(!this.isFormValid())return;

        let  {topic, comment, first_name, last_name, mobile,email, countryList, selectedCountry} = this.state;

        let param = {
            topic,
            comment,
            first_name,
            last_name,
            mobile,
            email,
            country:selectedCountry.label
        }
        this.setState({posting:true})
        WSManager.Rest(NC.CONTACT_US, param)
        .then(res => {

            if(res.response_code === 200){
                notify.show(res.message, "success", 5000);

                this.setState({
                    topic:"",
                    comment:"",
                    first_name:"",
                    last_name:"",
                    mobile:"",
                    email:"",
                    selectedCountry:""
                })

                setTimeout(() => {
                    window.location.replace('/');
                }, 50000)
            }else{

                this.setState({posting:false})
            }
        })
    }

    render() {


        let { topic, comment, first_name, last_name, mobile,email, countryList, selectedCountry, isFormValid , posting} = this.state;
        return (
            <React.Fragment>

                <div className="contact-us-sec">
                    <Helmet titleTemplate={`${MetaData.template} | %s`}>
                        <title>{MetaData.contact.title}</title>
                        <meta name="description" content={MetaData.contact.description} />
                        <meta name="keywords" content={MetaData.contact.keywords} />
                    </Helmet>
                    <Header {...this.props} />

                    <Container>
                        <div className={'cont-area'}>

                            <h1>Contact Us</h1>

                            <Row className={'justify-content-center'}>
                                <Col xl={9} lg={10} sm={12}>
                                    <div className={'form-box'}>

                                        <Row>
                                            <Col xs={12}>
                                                <div className={'form-group'}>
                                                    <label>Topic*</label>
                                                    <input
                                                        type={'text'}
                                                        name={'topic'}
                                                        value={topic || ''}
                                                        onChange={this.onChangeHandler}
                                                        className={'form-control type2'}
                                                    />
                                                </div>
                                            </Col>

                                            <Col xs={12} md={12}>
                                                <div className={'form-group'}>
                                                    <label>Comment*</label>
                                                    <textarea
                                                        name={'comment'}
                                                        value={comment || ''}
                                                        onChange={this.onChangeHandler}
                                                        className={'form-control type2'}
                                                    />
                                                </div>
                                            </Col>


                                            <Col xs={12} md={6}>
                                                <div className={'form-group'}>
                                                    <label>First Name*</label>
                                                    <input
                                                        type={'text'}
                                                        name={'first_name'}
                                                        value={first_name || ''}
                                                        onChange={this.onChangeHandler}
                                                        className={'form-control type2'}
                                                    />
                                                </div>
                                            </Col>

                                            <Col xs={12} md={6}>
                                                <div className={'form-group'}>
                                                    <label>Last Name*</label>
                                                    <input
                                                        type={'text'}
                                                        name={'last_name'}
                                                        value={last_name || ''}
                                                        onChange={this.onChangeHandler}
                                                        className={'form-control type2'}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={12}>
                                                <div className={'form-group'}>
                                                    <label>Email</label>
                                                    <input
                                                        type={'text'}
                                                        name={'email'}
                                                        value={email || ''}
                                                        onChange={this.onChangeHandler}
                                                        className={`form-control type2 ${mobile != "" && Validation.validate("email", email) !== "success" ? 'error':''}`}
                                                    />
                                                </div>
                                            </Col>

                                            <Col xs={12} md={6}>
                                                <div className={'form-group'}>
                                                    <label>Country*</label>
                                                    <div className={'form-select2'}>
                                                        <Select
                                                            onChange={(e) => this.selectChange(e, 'selectedCountry')}
                                                            options={countryList}
                                                            classNamePrefix="secondary"
                                                            className="select-secondary minusML10"
                                                            value={selectedCountry}
                                                            isSearchable={true}
                                                            isClearable={false}
                                                            menuPlacement="auto"
                                                            placeholder={''}

                                                        />
                                                    </div>

                                                </div>
                                            </Col>

                                            <Col xs={12} md={6}>
                                                <div className={'form-group'}>
                                                    <label>Mobile</label>
                                                    <input
                                                        type={'text'}
                                                        name={'mobile'}
                                                        value={mobile || ''}
                                                        onChange={this.onChangeHandler}
                                                        className={`form-control type2 ${mobile != "" && Validation.validate("phone_no", mobile) !== "success" ? 'error':''}`}
                                                    />
                                                </div>
                                            </Col>


                                            <Col xs={12}>
                                                <div className="text-center">
                                                    <Button
                                                        disabled={!isFormValid || posting ? true : false}
                                                        onClick={this.onSubmit}
                                                        className="btn btn-rounded contact-btn"
                                                    >Submit</Button>

                                                    <div><Link to={'/'} className={'bk-to-h'}>Back to home page</Link></div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>

                        </div>

                    </Container>

                </div>

            </React.Fragment>
        )
    }
}

export default ContactUs;