import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Images from 'Constants/Images';
// import { FacebookProvider, Page } from 'react-facebook';
import { TwitterTweetEmbed, TwitterTimelineEmbed } from 'react-twitter-embed';
import WSManager from "Helpers/WsManager";
import { Header } from 'Components';
import Config from 'Config';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAccord: null
        }
    }

    toggleAccord = key => {
        this.setState({ selectedAccord: this.state.selectedAccord == key ? null : key })
    }

    goTo = (key) => {
        if (!Config.isLOCAL) {
            if (key == "refsocial" || key == "refid") {
                Header.showComingSoonModal(key);
                return;
            }
        }

        if (WSManager.loggedIn()) {
            WSManager.goToAppUrl(key);
        } else {
            Header.LoginModalShow(key)
        }
    }


    render() {

        const { selectedAccord } = this.state;
        return (
            <React.Fragment>

                <footer className={'main-footer'}>

                    <Container className={'cmn-site-container'}>
                        <Row className={'justify-content-center '}>

                            <Col xl={12}>
                                <Row className={'justify-content-center nav-row'}>
                                    <Col xs={12} md={12} lg={2}>
                                        <div className={`mob-accord ${selectedAccord == 1 ? 'open' : ''}`}>
                                            <div className={'foot-head'} onClick={() => this.toggleAccord(1)}>COMPANY & POLICIES</div>
                                            <div className={'body'}>
                                                <ul className={'foot-link1'}>
                                                    <li><a href={'/assets/ABOUT_REFSOCIAL.pdf'} target={'_blank'}>About</a></li>
                                                    <li><a >Career Opportunities</a></li>
                                                    <li><a href={'/assets/REFSOCIAL_COMMUNITY_GUIDELINES.pdf'} target={'_blank'}>Community Guideline</a></li>
                                                    {/* <li><a href={'/assets/ReFPredictorCorporateInformation.pdf'} target={'_blank'}>Corporate Information</a></li> */}
                                                    {/* <li><Link to={'/'}>Responsible Gaming</Link></li> */}
                                                    {/* <li><a href={'/assets/ReFPredictorLeague-Rules.pdf'} target={'_blank'}>Rules</a></li> */}
                                                    {/* <li><a href={'/assets/ReFPredictorFAQs.pdf'} target={'_blank'}>FAQ's</a></li> */}
                                                    <li><a href={'/assets/REFSOCIAL_TERMS_OF_SERVICE.pdf'} target={'_blank'}>Terms of Service</a></li>
                                                    <li><a href={'/assets/REFSOCIAL_PRIVACY_POLICY.pdf'} target={'_blank'}>Privacy Policy</a></li>
                                                    <li><Link to={'/contact'}>Contact/ Feedback</Link></li>
                                                </ul>
                                                <span className={'refsoc-logo'}><img src={Images.LOGO_PURPLE} className={'ref-logo'} /> REFSOCIAL</span>

                                            </div>
                                        </div>

                                    </Col>

                                    <Col xs={12} md={12} lg={2}>
                                        <div className={`mob-accord ${selectedAccord == 2 ? 'open' : ''}`}>
                                            <div className={'foot-head'} onClick={() => this.toggleAccord(2)}>SERVICES</div>
                                            <div className={'body'}>
                                                <ul className={'foot-link1'}>
                                                    <li><a onClick={() => this.goTo('refid')}>ReFID</a></li>
                                                    <li><a onClick={() => this.goTo('refsocial')}>ReFSocial</a></li>
                                                    <li><a onClick={() => this.goTo('refleague')}>ReFLeague</a></li>
                                                    <li><a onClick={() => this.goTo('reflive')}>ReFLive</a></li>
                                                    <li><a onClick={() => this.goTo('refpay')}>ReFPurse</a></li>
                                                </ul>

                                                <div className={'hdng2 '}><span >Membership</span></div>
                                                <ul className={'foot-link2'}>
                                                    <li><a >Freemium</a></li>
                                                    <li><a >Premium</a></li>
                                                    <li><a >Premium+</a></li>
                                                </ul>
                                            </div>
                                        </div>

                                    </Col>

                                    <Col xs={12} md={12} lg={2}>
                                        <div className={`mob-accord ${selectedAccord == 3 ? 'open' : ''}`}>
                                            <div className={'foot-head'} onClick={() => this.toggleAccord(3)}>FOR THE FANS</div>
                                            <div className={'body'}>
                                                <ul className={'foot-link1'}>
                                                    <li><a >Connect</a></li>
                                                    <li><a >Engage</a></li>
                                                    <li><a >Reward</a></li>



                                                </ul>

                                                <div className={'hdng2 mt-2'}><span >For Brands</span></div>
                                                <ul className={'foot-link2'}>
                                                    <li><a >Consumer Engagement</a></li>
                                                    <li><a >Advertising</a></li>
                                                    <li><a >Loyalty & Rewards</a></li>
                                                </ul>

                                                <div className={'hdng2 mt-2'}><span >For Influencers & Gamers</span></div>
                                                <ul className={'foot-link2'}>
                                                    <li><a >Channels Remuneration</a></li>
                                                    <li><a >Contests Remuneration</a></li>
                                                    <li><a >Group Donations & thrifts</a></li>
                                                </ul>
                                            </div>
                                        </div>

                                    </Col>

                                    <Col xs={12} md={12} lg={3}>
                                        <div className={`mob-accord ${selectedAccord == 4 ? 'open' : ''}`}>
                                            <div className={'foot-head'} onClick={() => this.toggleAccord(4)}>RECENT POSTS</div>
                                            <div className={'body'}>
                                                {/* <div className="iframe-container">
                                                    <FacebookProvider appId="441447502995003" >
                                                        <Page href="https://www.facebook.com/refpredictor/" tabs="timeline" />
                                                    </FacebookProvider>

                                                </div> */}

                                                {/* <div className="iframe-container">
                                                    <TwitterTweetEmbed
                                                        tweetId={'1421251922607157249'}
                                                    />
                                                </div> */}

                                                <div className={'coming-soon-box'}>
                                                    <img src={Images.COMING_SOON} />
                                                    <div className={'txt-cs'}>Coming Soon</div>
                                                </div>


                                            </div>
                                        </div>

                                    </Col>

                                    <Col xs={12} md={12} lg={3} className={'desktop-hidden'}>
                                        <div className={`mob-accord ${selectedAccord == 5 ? 'open' : ''}`}>
                                            <div className={'foot-head'} onClick={() => this.toggleAccord(5)}>Downloads</div>
                                            <div className={'body'}>

                                                <ul className={'foot-link2'}>
                                                    <li><a >Mac/ PC</a></li>
                                                    <li><a >iPhone/ Android</a></li>
                                                </ul>
                                            </div>
                                        </div>

                                    </Col>

                                    <Col xs={12} md={12} lg={2}>
                                        <div className={'foot-head social-head'}>SOCIAL MEDIA</div>
                                        <ul className={'social-ics'}>
                                            {/* <li>
                                                <a href={'https://www.facebook.com/refpredictor/'} target={'_blank'}><i className={'icon-fb-ic'}></i> <span>Facebook</span></a>
                                            </li> */}
                                            <li>
                                                <a href={'https://youtube.com/channel/UCzPyzZvWiyNsfXMMoS1d7mQ'} target={'_blank'}><i className={'icon-youtube-ic'}></i> <span>Youtube</span></a>
                                            </li>
                                            <li>
                                                <a href={'https://twitter.com/ReFSocialapp'} target={'_blank'}><i className={'icon-twitter-ic'}></i> <span>Twitter</span></a>
                                            </li>
                                            <li>
                                                <a href={''} target={'_blank'}><i className={'icon-logo-2'}></i> <span>ReFSocial</span></a>
                                            </li>
                                            <li>
                                                <a href={'https://wa.me/+2348037526464'} target={'_blank'}><i className={'icon-whatsapp-ic'}></i> <span>Whatsapp</span></a>
                                            </li>
                                            <li>
                                                <a href={'https://www.instagram.com/ReFSocialapp/'} target={'_blank'}><i className={'icon-insta-ic'} target={'_blank'}></i> <span>Instagram</span></a>
                                            </li>
                                        </ul>

                                        <div className={'mobile-hidden'}>
                                            <div className={'hdng2 mt-2'}><span >Downloads</span></div>
                                            <ul className={'foot-link2'}>
                                                <li><a >Mac/ PC</a></li>
                                                <li><a >iPhone/ Android</a></li>
                                            </ul>
                                        </div>
                                    </Col>



                                </Row>
                            </Col>

                        </Row>



                        <Row className={'ft-strip justify-content-center text-center'}>
                            <Col xs={12} md={9}>© ReFSocial ALL RIGHTS RESERVED | COPYRIGHT STATEMENT & BRAND GUIDE LINES</Col>
                        </Row>
                    </Container>
                </footer>

            </React.Fragment>
        )
    }
}


export default Footer;