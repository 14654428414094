import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationEng from "./en.json";
import translationHi from "./hi.json";

import  * as CONSTANT  from "Constants/Constants";
import { Utils } from "Helpers";

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    // debug: process.env.NODE_ENV === "development",
    lng: Utils.getCookie('refpredictor_lang') === '' ? CONSTANT.DEFAULT_LNG : Utils.getCookie('refpredictor_lang'),
    fallbackLng: CONSTANT.FALLBACK_LNG, // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    languages: ['en', 'hi'],
    resources: {
      en: {
        translations: translationEng
      },
      hi: {
        translations: translationHi
      },
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations"
  });

export default i18n;
