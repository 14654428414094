import React from 'react';
import { Row, Col, Modal, Button } from "react-bootstrap";
import Images from 'Constants/Images';

class ComingSoonModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {


        const { show, onHide, module } = this.props;
        return (
            <React.Fragment>

                <Modal
                    show={show}
                    onHide={onHide}
                    dialogClassName="coming-soon-modal"

                    className="center-modal"
                >

                    <div className={'hd-img'}><span>
                        <img
                            src={
                                module == 'refsocial' ? Images.REFSOCIAL :
                                    module == 'refleague' ? Images.REFPREDICTOR :
                                        module == 'reflive' ? Images.REFLIVE :
                                            module == 'refpay' ? Images.REFPAY :
                                                Images.LOGO_PURPLE
                            }
                        /></span></div>

                    <div className={'cm-body'}>
                        <h2>Oops…</h2>
                        <h4>You caught us!</h4>
                        <div className={'t-content'}>
                            We are enhancing this platform. You will be able to socialize soon.
                            <br /><br />
                            In the meantime, you can view the site's content to know more about ReFSocial, what we stand for and how we align with your thoughts. Then, Sign Up.😉
                            <br /><br />
                            Got any idea or feedback you'd like to share, or tell us how ReFSocial can solve an existing problem?
                        </div>

                        {/* <ul className={'social-ics'}>
                           
                            <li>
                                <a href={'https://youtube.com/channel/UCzPyzZvWiyNsfXMMoS1d7mQ'} target={'_blank'}><i className={'icon-youtube-ic'}></i></a>
                            </li>
                            <li>
                                <a href={'https://twitter.com/ReFSocialapp'} target={'_blank'}><i className={'icon-twitter-ic'}></i> </a>
                            </li>
                            <li>
                                <a href={''} target={'_blank'}><i className={'icon-logo-2'}></i> </a>
                            </li>
                            <li>
                                <a href={'https://wa.me/+2348037526464'} target={'_blank'}><i className={'icon-whatsapp-ic'}></i></a>
                            </li>
                            <li>
                                <a href={'https://www.instagram.com/ReFSocialapp/'} target={'_blank'}><i className={'icon-insta-ic'} target={'_blank'}></i></a>
                            </li>
                        </ul> */}

                        <div className={'cm-foot'}>
                            <button
                                className={'btn btn-rounded close-btn'}
                                onClick={()=> {
                                    onHide();
                                    window.location.replace('/contact')
                                }}
                            >CONTACT US</button>
                        </div>
                    </div>

                </Modal>
            </React.Fragment>
        )
    }
}


export default ComingSoonModal;