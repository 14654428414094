const MetaData = {
    template: "ReFSocial",
    membership: {
        title: "Membership Plan",
        description: "Membership Plan page",
        keywords: "Membership Plan, freemium, premium, premium+"
    },
    contact: {
        title: "Contact Us",
        description: "Contact us page",
        keywords: "contact"
    },
    notification: {
        title: "Notification",
        description: "Notification page",
        keywords: "fantasy, contest"
      },
}

export default MetaData;