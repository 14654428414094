import React from "react";
import { Row, Col, Button, Modal, FormGroup } from "react-bootstrap";
import { Loader, Header } from "Components";
import { notify } from "react-notify-toast";
import FloatingLabel, { floatingStyles, focusStyles, inputStyles, labelStyles } from "floating-label-react";
import Select from "react-select";
import WSManager from "Helpers/WsManager";
import * as NC from "Constants/NetworkingConstants";
import _ from "lodash";
import * as Constants from "Constants/Constants";
import Validation from "Helpers/validation";
import Images from 'Constants/Images';
import Config from 'Config';
import Slider from "react-slick";

import util from "util";

const md5 = require("md5");
const formInputStyle = {
  floating: {
    ...floatingStyles,
    color: "rgba(255, 255, 255, 0.6)",
    fontSize: "12px",
    borderBottomColor: "#e1e1e1",
    fontFamily: "MuliRegular"
  },
  focus: {
    ...focusStyles,
    borderColor: "#e1e1e1"
  },
  input: {
    ...inputStyles,
    borderBottomWidth: 1,
    borderBottomColor: "rgba(255, 255, 255, 0.4)",
    width: "100%",
    fontSize: "16px",
    color: "#fff",
    fontFamily: "MuliBold",
    padding: "16px 0px 10px",
    backgroundColor: "transparent"
  },
  label: {
    ...labelStyles,
    paddingBottom: "0px",
    marginBottom: "0px",
    width: "100%",
    fontSize: "12px",
    color: "rgba(255, 255, 255, 0.8)",
    fontFamily: "MuliRegular"
  }
};

export default class CompleteProfile extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      selectedLanguage: "",
      selectedLeague: "",
      selectedClub: "",
      selectedCountry: "",
      selectedState: "",
      clubs: [],
      submitClick: false,
      userProfile: WSManager.getProfile(),
      isLoading: false,
      countryList: [],
      stateList: [],
      profileImageFile: "",
      profileImage: "",
      bannerImageFile: "",
      bannerImage: "",
      user_type: this.props.match && this.props.match.params ? (this.props.match.params.user_type || "") : '',
      basicInfoTabCompleted: true,
      activeTab: '2',
      userTabCompleted: false,
      membershipTabCompleted: false,
      about: '',
      isUserInfoValid: false,
      memberShipTab: '',
      subPlanType: '',
      display_name: '',
      website: '',
      master_plans: [],
      isMembershipPlanValid: true,
      currentShowPlan: {},
      username: '',
      password: '',
      confirmPassword: '',
      isShowPassword: false,
      isShowPassword2: false,
      user_balance: Constants.userBalanceInfo.user_balance || "",
      proceedMsg: "",
      sports_list: Constants.sports_list,
      selectedSports: Constants.sports_list[0]
    };
  }


  showPassword = (key) => {
    this.setState({
      [key]: !this.state[key]
    });
  };

  handleLanguageChange = selectedOption => {
    this.setState({ selectedLanguage: selectedOption }, this.IsFormValid);
  };

  handleSportsChange = itm => {
    this.setState({ selectedSports: itm }, this.IsFormValid);
  }

  handleLeagueChange = selectedOption => {
    // let tmpArray = [];
    // _.map(Constants.clubsData[selectedOption.value], (item, idx) => {
    //   tmpArray.push({ label: item, value: item });
    // });
    this.setState({
      selectedLeague: selectedOption,
      clubs: Constants.clubsData[selectedOption.league_id],
      selectedClub: ""
    }, this.IsFormValid);
  };

  handleClubChange = selectedOption => {
    this.setState({ selectedClub: selectedOption }, this.IsFormValid);
  };

  handleCountryChange = selectedOption => {
    this.setState({ selectedCountry: selectedOption, selectedState: "" }, this.IsFormValid);
    this.callWSGetStateData(selectedOption.value);
  };

  handleStateChange = selectedOption => {
    this.setState({ selectedState: selectedOption }, this.IsFormValid);
  };

  IsFormValid() {
    let {
      selectedClub,
      selectedLeague,
      selectedState,
      selectedCountry,
      selectedLanguage,
      about,
      display_name,
      website,
      password,
      username,
      confirmPassword,
      selectedSports
    } = this.state;

    var isValid = true;



    if (selectedLanguage === "") {
      isValid = false;
    }
    // else if (selectedCountry === "") {
    //   isValid = false;
    // } 
    else if (selectedSports === "") {
      isValid = false;
    }
    else if (selectedState === "") {
      isValid = false;
    } else if (selectedLeague === "") {
      isValid = false;
    } else if (selectedClub === "") {
      isValid = false;
    }
    else if (about === "") {
      isValid = false;
    }
    else if (display_name === "" || display_name.length > 15) {
      isValid = false;
    }
    // else if (website !== "" && Validation.validate("url", website) !== "success") {
    //   isValid = false;
    // }
    else if (
      Validation.validate("required", username) !== "success" ||
      Validation.validate("userName", username) !== "success" ||
      username.length > 12
    ) {
      isValid = false;
    }
    else if (
      Validation.validate("required", password) !== "success" ||
      Validation.validate("password", password) !== "success" ||
      password !== confirmPassword
    ) {
      isValid = false;
    }

    this.setState({ isUserInfoValid: isValid })
    return isValid;
  }


  membershipValid = () => {
    let {
      memberShipTab,
      subPlanType,
      master_plans
    } = this.state;
    var isValid = true;
    if (memberShipTab === "") {
      isValid = false;
    }
    let obj = master_plans.find(mp => mp.master_plan_id === memberShipTab);
    if (obj.sub_plan.length > 0) {
      if (subPlanType === '') {
        isValid = false;
      }
    }
    this.setState({ isMembershipPlanValid: isValid })
    return isValid;
  }

  onSubmit = e => {
    // e.preventDefault();
    this.setState({
      submitClick: true
    });

    let {
      selectedClub,
      selectedLeague,
      selectedState,
      selectedCountry,
      selectedLanguage,
      userProfile,
      profileImage,
      bannerImage,
      // isUserInfoValid,
      website,
      about,
      display_name,
      memberShipTab,
      subPlanType,
      username,
      password,
      confirmPassword,
      selectedSports
    } = this.state;

    if (this.IsFormValid()) {
      this.setState({ isLoading: true });
      let param = {
        image: profileImage,
        cover_image: bannerImage,
        master_country_id: userProfile.master_country_id,
        master_state_id: selectedState.value,
        language: selectedLanguage.value,
        fav_league: selectedLeague.league_id,
        fav_club: selectedClub.id,
        first_name: userProfile.first_name,
        last_name: userProfile.last_name,
        // user_name: userProfile.user_name,
        dob: userProfile.dob,
        gender: userProfile.gender,
        phone_no: userProfile.phone_no,
        tagline: userProfile.tagline,
        // user_website: userProfile.user_website,
        // phone_network: userProfile.phone_network,
        user_website: website,
        about: about,
        display_name: display_name,
        user_name: username,
        password: md5(password),
        confirm_password: md5(confirmPassword),
        complete_profile_update: '1',
        fav_sport: selectedSports.sports_id
        // plan_id: memberShipTab,
        // sub_plan_id: subPlanType
      };

      WSManager.Rest(NC.UPDATE_PROFILE, param).then(
        responseJson => {
          this.setState({ isLoading: false });
          if (responseJson && responseJson.response_code === NC.successCode) {
            responseJson.data.user_profile['plan_id'] = WSManager.getProfile().plan_id;
            responseJson.data.user_profile['plan_round_id'] = WSManager.getProfile().plan_round_id;
            responseJson.data.user_profile['security_pin_enabled'] = WSManager.getProfile().security_pin_enabled;
            WSManager.setProfile(responseJson.data.user_profile);
            WSManager.setProfileCompleted("1");
            // this.props.IsCompleteProfileHide();
            // if (this.state.user_type == 'pundit') {
            //   this.props.history.push('/dashboard/0')
            // }
            // else {
            //   window.location.reload();
            // }

            this.setState({
              userTabCompleted: true,
              activeTab: '3'
              // currentShowPlan:this.state.master_plans[0] 
            }, this.membershipValid)

          }
        }
      );
    }
  };

  callWSGetCountryData() {
    WSManager.Rest(NC.GET_ALL_COUNTRY, {}).then(responseJson => {
      if (responseJson.response_code === NC.successCode) {
        let tmpArray = [];
        _.map(responseJson.data.country_list, (item, idx) => {
          tmpArray.push({
            label: item.country_name,
            value: item.master_country_id
          });
        });
        this.setState({ countryList: tmpArray });
      }
    });
  }

  callWSGetStateData(master_country_id) {
    this.setState({ isLoading: true });
    WSManager.Rest(NC.GET_ALL_STATE, {
      master_country_id: master_country_id
    }).then(responseJson => {
      this.setState({ isLoading: false });

      if (responseJson.response_code === NC.successCode) {
        let tmpArray = [];
        _.map(responseJson.data.state_list, (item, idx) => {
          tmpArray.push({
            label: item.state_name,
            value: item.master_state_id
          });
        });
        this.setState({ stateList: tmpArray });
      }
    });
  }


  getMembershipData = () => {
    WSManager.Rest(NC.GET_MEMBERSHIP_MASTER_DATA, {})
      .then(responseJson => {
        if (responseJson.response_code === NC.successCode) {
          this.setState({
            master_plans: responseJson.data,
            currentShowPlan: responseJson.data.length > 0 ? responseJson.data[0] : {},
            memberShipTab: responseJson.data.length > 0 ? responseJson.data[0].master_plan_id : '',
          })
        }
      })

  }


  getMyProfile = () => {
    WSManager.Rest(NC.GET_MY_PROFILE).then(responseJson => {
      if (responseJson.response_code === NC.successCode) {
        WSManager.setProfile(responseJson.data.user_profile);
        this.setState({
          userProfile: responseJson.data.user_profile
        }, () => {
          this.callWSGetStateData(responseJson.data.user_profile.master_country_id);
        }
        )
      }
    });
  }

  componentWillMount() {
    // let {userProfile} = this.state;
    // this.callWSGetCountryData();
    // Header.callGetMyProfileApi()
    // this.callWSGetStateData(userProfile.master_country_id);


    this.getMyProfile()
    this.getMembershipData()
    this.getBalance()
  }

  getBalance = () => {
    Constants.SetUserBalance.callBalanceApi()
      .then(isSuccess => {
        this.setState({
          user_balance: Constants.userBalanceInfo.user_balance || ""
        })
      });
  }

  onSelectProfilePic(e) {
    e.preventDefault();
    let reader = new FileReader();
    let mfile = e.target.files[0];
    reader.onloadend = () => {
      if (mfile.size / 1024000 > 40) {
        notify.show(Constants.Messages.file_size, "error", 5000);
      } else {
        this.setState(
          {
            profileImageFile: mfile,
            profileImage: reader.result
          },
          () => {
            this.upload.value = "";
            this.uploadImage(this);
          }
        );
      }
    };
    reader.readAsDataURL(mfile);
  }

  uploadImage(globalThis) {
    globalThis.setState({ isLoading: true });
    var data = new FormData();
    data.append("userfile", globalThis.state.profileImageFile);

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        globalThis.setState({ isLoading: false });
        var response = JSON.parse(this.responseText);
        if (response !== "" && response.response_code === NC.successCode) {
          var imagePath = response.data.image_path;
          globalThis.setState({ profileImage: imagePath });
        } else {

          globalThis.setState({ profileImage: '', profileImageFile: '' });

          var errorMsg = WSManager.parseErrorMessage(response)
          if (response.response_code == NC.sessionExpireCode) {
          } else {
            notify.show(errorMsg, "error", 5000);
          }
        }
      }
    });

    xhr.open("POST", Config.base_url + NC.DO_UPLOAD);
    xhr.setRequestHeader("session_key", WSManager.getToken());
    xhr.send(data);
  }

  onSelectBannerPic(e) {
    e.preventDefault();
    let reader = new FileReader();
    let mfile = e.target.files[0];
    reader.onloadend = () => {
      if (mfile.size / 1024000 > 40) {
        notify.show(Constants.Messages.file_size, "error", 5000);
      } else {
        this.setState(
          {
            bannerImageFile: mfile,
            bannerImage: reader.result
          },
          () => {
            this.bannerUpload.value = "";
            this.uploadBannerImage(this);
          }
        );
      }
    };
    reader.readAsDataURL(mfile);
  }

  uploadBannerImage(globalThis) {
    globalThis.setState({ isLoading: true });
    var data = new FormData();
    data.append("usercoverfile", globalThis.state.bannerImageFile);

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        globalThis.setState({ isLoading: false });
        var response = JSON.parse(this.responseText);
        if (response !== "" && response.response_code === NC.successCode) {
          var imagePath = response.data.image_path;
          globalThis.setState({ bannerImage: imagePath });
        } else {
          var errorMsg = WSManager.parseErrorMessage(response)
          if (response.response_code == NC.sessionExpireCode) {
          } else {
            notify.show(errorMsg, "error", 5000);
          }
          globalThis.setState(
            {
              bannerImageFile: '',
              bannerImage: ''
            });

        }
      }
    });

    xhr.open("POST", Config.base_url + NC.DO_UPLOAD_BANNER);
    xhr.setRequestHeader("session_key", WSManager.getToken());
    xhr.send(data);
  }


  goTo = (e, key) => {
    e.preventDefault();

    if (key === this.state.activeTab) return;
    if (key === '3') {
      if (!this.IsFormValid()) { return; }
      this.onSubmit();
    }

  }


  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, this.IsFormValid);
  }


  MembershipTabChange = (key, i) => {
    let arr = [...this.state.master_plans];
    let checkArr = arr[i].sub_plan.length > 0;

    this.setState(
      {
        memberShipTab: key,
        subPlanType: checkArr ? arr[i].sub_plan[0].master_plan_id : '',
        currentShowPlan: arr[i],
        proceedMsg: ""
      },
      this.membershipValid
    )
  }

  subPlanTypeChange = (key) => {
    this.setState({ subPlanType: key, proceedMsg: "" }, this.membershipValid)
  }

  choosePlan = () => {

    let {
      memberShipTab,
      subPlanType,
      master_plans,
      currentShowPlan,
      user_balance
    } = this.state;
    let obj = master_plans.find(mp => mp.master_plan_id === memberShipTab);


    if (obj.amount == 0) {
      this.props.IsCompleteProfileHide();
      Header.CongratsModalShow('You are now a Freemium member');
    } else {


      let plan_amount = currentShowPlan.amount;
      let plan_discount = currentShowPlan.discount_percent;
      let plan_id = currentShowPlan.master_plan_id
      if (subPlanType !== '') {
        let getSubplans = currentShowPlan.sub_plan.find(obj => obj.master_plan_id == subPlanType);
        plan_amount = getSubplans.amount;
        plan_discount = getSubplans.discount_percent;
        plan_id = subPlanType
      }

      let discontPercentage = plan_discount ? parseFloat(plan_discount) : 0;
      let DiscountedAmt = parseFloat(plan_amount) * discontPercentage / 100;
      let ActualAmt = parseFloat(plan_amount) - parseFloat(DiscountedAmt);

      let balance = user_balance.real_amount + user_balance.winning_amount;

      if (parseFloat(balance) < parseFloat(ActualAmt)) {
        let obj = {
          isMembership: true,
          plan_id: plan_id,
          plan_amount: ActualAmt,
          countineType: '',
        }
        let url = `isMembership=true&plan_id=${plan_id}&plan_amount=${ActualAmt}&countineType=null&from=refpredictor&topage=add-funds`
        notify.show(util.format(Constants.Messages.less_fund, 'subscribe'), "error", 4000);
        WSManager.goToAppUrl('refpay', url);
      }
      else {
        Header.showPaymentConfirmModal(plan_id, ActualAmt, this.state.countineType);
        this.props.IsCompleteProfileHide();
      }
    }

  }



  proceedToPayConfirm = () => {
    let { currentShowPlan, subPlanType } = this.state;
    let msg = "";
    let subplanTypeName = ""
    let subplanDuration = ""

    if (currentShowPlan.master_plan_id == "1") {
      msg = `Your Freemium membership (No subscription) is confirmed. Proceed with limited benefits only.`
    }
    else if (currentShowPlan.master_plan_id == "2") {

      if (subPlanType != "") {
        currentShowPlan.sub_plan.forEach(itm => {
          if (subPlanType == itm.master_plan_id) {

            if (itm.plan_name == "Bi-weekly") {
              subplanTypeName = "Bi-weekly"
              subplanDuration = "1/2 week"
            }

            if (itm.plan_name == "Weekly") {
              subplanTypeName = "Weekly"
              subplanDuration = "1 week"
            }

            if (itm.plan_name == "Monthly") {
              subplanTypeName = "Monthly"
              subplanDuration = "1 month"
            }
          }
        })

        msg = `Your ${currentShowPlan.plan_name} membership (${subplanTypeName}) is confirmed. Please subscribe now to start enjoying more benefits and privileges for ${subplanDuration}.`

      }
      else {
        msg = `Your ${currentShowPlan.plan_name} membership is confirmed. Please subscribe now to start enjoying more benefits and privileges.`
      }

    }

    else if (currentShowPlan.master_plan_id == "3") {
      msg = `Your Premium+ membership (Annual subscription) is confirmed. Please subscribe now to start enjoying the most benefits and highest privileges for 1 year.`
    }

    this.setState({ proceedMsg: msg })

  }


  render() {
    const { IsCompleteProfileShow, IsCompleteProfileHide } = this.props;
    const {
      selectedLanguage,
      selectedLeague,
      selectedClub,
      clubs,
      countryList,
      selectedCountry,
      selectedState,
      stateList,
      isLoading,
      submitClick,
      profileImage,
      bannerImage,
      activeTab,
      userTabCompleted,
      membershipTabCompleted,
      about,
      isUserInfoValid,
      basicInfoTabCompleted,
      memberShipTab,
      userProfile,
      subPlanType,
      display_name,
      website,
      master_plans,
      isMembershipPlanValid,
      currentShowPlan,
      username,
      password,
      confirmPassword,
      isShowPassword,
      isShowPassword2,
      proceedMsg,
      sports_list,
      selectedSports
    } = this.state;
//console.log("master",master_plans);
    const settings = {
      
      customPaging: function(i){
        //console.log("sheetal++", i);
        return (
          <div className={'mem-tab-ar'}>
              {
                master_plans && 
                  <div className="mem-tab"
                      key={i}
                    // onClick={() => this.MembershipTabChange(master_plans_id[i].master_plan_id, i)}
                     // className={`mem-tab ${memberShipTab === master_plans_id[i].master_plan_id ? 'active' : ''}`}
                    >
                      <span>{master_plans[i].plan_name}</span>
                    </div>

              }
                
            </div>
        );
      },
      dots: true,
      dotsClass: "slick-dots slick-thumb",
      infinite: false,
      speed: 500,
      // centerMode: true,
      // centerPadding: "60px",
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true
    };
    return (
      <Modal
        show={IsCompleteProfileShow}
        // onHide={IsCompleteProfileHide}
        dialogClassName={`custom-modal-with-bg confirmation-modal cp-profile-modal ${activeTab === '3' ? 'member-tab-active' : ''}`}
      >
        {isLoading && <Loader />}


        <div className="confirmation-modal-header complete-profile-modal">
          <div className={'f-gr-area'}>
            <img src={Images.LOGO_PURPLE} className={'logo'} />
            {/* <a href className="modal-close-btn" onClick={() => IsCompleteProfileHide()}>
                  <i className="icon-close" />
                </a> */}

            <div className={'tabie-ar'}>
              <div className={`tbie ${basicInfoTabCompleted ? 'completed' : ''}`}>
                <span>
                  <img src={Images.CHECKED_W} />
                </span>
                <div>1. Basic Info</div>
              </div>
              <div
                className={`tbie ${activeTab === '2' ? 'active' : ''} ${userTabCompleted && activeTab !== '2' ? 'completed' : ''}`}
              // onClick={(e) => this.goTo(e, '2')}
              >
                <span>
                  <img src={
                    userTabCompleted && activeTab !== '2' ? Images.CHECKED_W : Images.USER} />
                </span>
                <div>2. Update Info</div>
              </div>
              <div
                className={`tbie ${activeTab === '3' ? 'active' : ''} ${membershipTabCompleted && activeTab !== '3' ? 'completed' : ''}`}
              // onClick={(e) => this.goTo(e, '3')}
              >
                <span>
                  <img src={membershipTabCompleted && activeTab !== '3' ? Images.CHECKED_W : Images.WALLET} />
                </span>
                <div>3. Membership</div>
              </div>
            </div>
          </div>
          {/* <div className="p-20">
                <a
                  href="/dashboard/0"
                  onClick={() => IsCompleteProfileHide()}
                  className="skip-btn"
                >
                  Skip
                </a>
              </div> */}
          {/* <div className="modal-title">Complete Profile</div> */}
          {activeTab === '2' ?
            <React.Fragment>
              <div className="user-profile-pic mt-5">
                <figure>
                  <input
                    id="myInput"
                    type="file"
                    accept="image/*"
                    ref={ref => (this.upload = ref)}
                    style={{ display: "none" }}
                    onChange={e => this.onSelectProfilePic(e)}
                  />
                  <img
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src = Images.DEFAULT_USER;
                    }}

                    src={profileImage || Images.DEFAULT_USER}
                    alt=""
                  />
                </figure>
                <span className="select-profile-section cursor-pointer" onClick={() => this.upload.click()}>
                  <i className="icon-camera"></i>
                </span>
              </div>
              <div
                className="upload-banner-section"
                style={{
                  backgroundImage: `url(${bannerImage})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat"
                }}
              >
                {bannerImage && (
                  <i
                    className="icon-cross-circular"
                    onClick={() =>
                      this.setState({ bannerImage: "", bannerImageFile: "" })
                    }
                  />
                )}
                <input
                  id="myInput"
                  type="file"
                  accept="image/*"
                  ref={ref => (this.bannerUpload = ref)}
                  style={{ display: "none" }}
                  onChange={e => this.onSelectBannerPic(e)}
                />
                {!bannerImage && (
                  <React.Fragment>
                    <p className="optional">Optional</p>
                    <h2>Upload Banner Image</h2>
                    <p>Format - jpeg, png</p>
                    <div
                      className="btn btn-rounded"
                      onClick={() => this.bannerUpload.click()}
                    >
                      upload
                    </div>
                  </React.Fragment>
                )}
              </div>

            </React.Fragment> : ''}
        </div>


        <div className="modal-overlay" />


        <Modal.Body>


          <div className={`cp-tab ${activeTab === '2' ? 'active' : ''}`}>
            <form className="form-section">

              <div className="verification-block">
                <Row>
                  <Col xs={12}>
                    <FormGroup
                      className={`input-label-center ${submitClick ?
                        Validation.validate("required", display_name) == "success" ?
                          'has-success' : 'has-error'
                        : ''
                        }`}
                    >
                      <FloatingLabel
                        styles={formInputStyle}
                        id="display_name"
                        name="display_name"
                        value={display_name}
                        placeholder="Display Name"
                        type="text"
                        onChange={this.handleChange}
                        maxLength={15}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <div className="verification-block">
                <Row>
                  <Col xs={12}>
                    <FormGroup

                      className={`input-label-center ${submitClick ?
                        Validation.validate("required", username) == "success" &&
                          Validation.validate("userName", username) == "success" ?
                          'has-success' : 'has-error'
                        : ''
                        }`}


                    >
                      <FloatingLabel
                        styles={formInputStyle}
                        id="Username"
                        name="username"
                        placeholder="Username"
                        type="text"
                        value={username}
                        maxLength="12"
                        autocomplete="off"
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <div className="help-text">
                      Username can’t be greater than 12 characters
                          </div>
                  </Col>
                </Row>
              </div>

              <div className="verification-block">
                <Row>
                  <Col xs={12}>
                    <FormGroup
                      className={`input-label-center ${submitClick ?
                        Validation.validate("required", password) == "success" &&
                          Validation.validate("password", password) == "success" ?
                          'has-success' : 'has-error'
                        : ''
                        }`}
                    >
                      <FloatingLabel
                        styles={formInputStyle}
                        id="Password"
                        name="password"
                        placeholder="Password"
                        value={password}
                        type={isShowPassword ? "text" : "password"}
                        required
                        maxLength="50"
                        autocomplete="off"
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                    <a

                      className="hidden-align"
                      onClick={() => this.showPassword('isShowPassword')}
                    >
                      {this.state.isShowPassword ? (
                        <img
                          src={Images.EYE_DARK_ICON}
                          width="23px"
                          className="opacity"
                          alt=""
                        />
                      ) : (
                          <img
                            src={Images.EYE_CANCEL_DARK_ICON}
                            width="23px"
                            alt=""
                          />
                        )}
                    </a>
                  </Col>
                  <Col xs={12}>
                    <div className="help-text">
                      Password is case sensitive, minimum 4 characters
                          </div>
                  </Col>
                </Row>
              </div>

              <div className="verification-block">
                <Row>
                  <Col xs={12}>
                    <FormGroup
                      className={`input-label-center ${submitClick ?
                        Validation.validate("required", confirmPassword) == "success" &&
                          Validation.validate("password", confirmPassword) == "success" &&
                          password === confirmPassword
                          ?
                          'has-success' : 'has-error'
                        : ''
                        }`}
                    >
                      <FloatingLabel
                        styles={formInputStyle}
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        type={isShowPassword2 ? "text" : "password"}
                        required
                        maxLength="50"
                        autocomplete="off"
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                    <a

                      className="hidden-align"
                      onClick={() => this.showPassword('isShowPassword2')}
                    >
                      {this.state.isShowPassword2 ? (
                        <img
                          src={Images.EYE_DARK_ICON}
                          width="23px"
                          className="opacity"
                          alt=""
                        />
                      ) : (
                          <img
                            src={Images.EYE_CANCEL_DARK_ICON}
                            width="23px"
                            alt=""
                          />
                        )}
                    </a>
                  </Col>

                </Row>
              </div>

              <div className="verification-block mt-0 p-0">
                <Row>
                  <Col xs={12}>
                    <FormGroup

                      className={`input-label-center input-transparent font-14 ${submitClick ?
                        selectedState !== ""
                          ?
                          'has-success' : 'has-error error'
                        : ''
                        }`}

                    >
                      <div className="select-gender">
                        <label style={formInputStyle.label}>Location</label>
                        <div className="genderStyle">
                          <Select
                            onChange={this.handleStateChange}
                            options={stateList}
                            classNamePrefix="secondary"
                            className="select-secondary minusML10"
                            placeholder="Select Location"
                            value={selectedState}
                            menuPlacement="auto"
                            isSearchable={true}
                            isClearable={false}
                            theme={theme => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary: "#023320"
                              }
                            })}
                          />
                        </div>
                        <span className="select-arr">
                          <i className="icon-arrow-sort" />
                        </span>
                        <div className="gender-border col-sm-12" />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <div className="verification-block">
                <Row>
                  <Col xs={12}>
                    <FormGroup
                      className={`input-label-center ${submitClick ?
                        Validation.validate("required", about) == "success" ?
                          'has-success' : 'has-error error'
                        : ''
                        }`}
                    >
                      <FloatingLabel
                        styles={formInputStyle}
                        id="about"
                        name="about"
                        value={about}
                        placeholder="About/Bio"
                        type="text"
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>


              <div className="verification-block mt-0 p-0">
                <Row>
                  <Col xs={12}>
                    <FormGroup

                      className={`input-label-center input-transparent font-14 ${submitClick ?
                        selectedLanguage !== "" ?
                          'has-success' : 'has-error error'
                        : ''
                        }`}
                    >
                      <div className="select-gender">
                        <label style={formInputStyle.label}>Language</label>
                        <div className="genderStyle">
                          <Select
                            onChange={this.handleLanguageChange}
                            options={Constants.languages}
                            classNamePrefix="secondary"
                            className="select-secondary minusML10"
                            placeholder="Select Language"
                            isSearchable={true}
                            isClearable={false}
                            value={selectedLanguage}
                            menuPlacement="auto"
                            theme={theme => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary: "#023320"
                              }
                            })}
                          />
                        </div>
                        <span className="select-arr">
                          <i className="icon-arrow-sort" />
                        </span>
                        <div className="gender-border col-sm-12" />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <div className="verification-block mt-0 p-0">
                <Row>
                  <Col xs={12}>
                    <FormGroup

                      className={`input-label-center input-transparent font-14 ${submitClick ?
                        selectedSports !== "" ?
                          'has-success' : 'has-error error'
                        : ''
                        }`}
                    >
                      <div className="select-gender">
                        <label style={formInputStyle.label}>Choose Favorite Sport</label>
                        <div className="genderStyle">
                          <Select
                            onChange={this.handleSportsChange}
                            options={sports_list}
                            classNamePrefix="secondary"
                            className="select-secondary minusML10"
                            placeholder="Select Sports"
                            isSearchable={true}
                            isClearable={false}
                            value={selectedSports}
                            getOptionLabel={(option) => option.sports_name}
                            getOptionValue={(option) => option.sports_id}
                            menuPlacement="auto"
                            theme={theme => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary: "#023320"
                              }
                            })}
                          />
                        </div>
                        <span className="select-arr">
                          <i className="icon-arrow-sort" />
                        </span>
                        <div className="gender-border col-sm-12" />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              {/* <div className="verification-block mt-0 p-0">
                    <Row>
                      <Col xs={12}>
                        <FormGroup
                          validationState={
                            submitClick
                              ? selectedCountry === ""
                                ? "error"
                                : ""
                              : ""
                          }
                          className="input-label-center input-transparent font-14"
                        >
                          <div className="select-gender">
                            <label style={formInputStyle.label}>Country</label>
                            <div className="genderStyle">
                              <Select
                                onChange={this.handleCountryChange}
                                options={countryList}
                                classNamePrefix="secondary"
                                className="select-secondary minusML10"
                                placeholder="Select Country"
                                value={selectedCountry}
                                isSearchable={true}
                                isClearable={false}
                                menuPlacement="auto"
                                theme={theme => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#023320"
                                  }
                                })}
                              />
                            </div>
                            <span className="select-arr">
                              <i className="icon-arrow-sort" />
                            </span>
                            <div className="gender-border col-sm-12" />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div> */}

              <div className="verification-block mt-0 p-0">
                <Row>
                  <Col xs={12}>
                    <FormGroup

                      className={`input-label-center input-transparent font-14 ${submitClick ?
                        selectedLeague !== "" ?
                          'has-success' : 'has-error error'
                        : ''
                        }`}
                    >
                      <div className="select-gender">
                        <label style={formInputStyle.label}>
                          Favorite League
                          </label>
                        <div className="genderStyle">
                          <Select
                            onChange={this.handleLeagueChange}
                            options={Constants.leagues}
                            classNamePrefix="secondary"
                            className="select-secondary minusML10"
                            placeholder="Select League"
                            isSearchable={true}
                            isClearable={false}
                            menuPlacement="auto"
                            value={selectedLeague}
                            getOptionLabel={(option) => option.league_abbr}
                            getOptionValue={(option) => option.league_id}
                            theme={theme => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary: "#023320"
                              }
                            })}
                          />
                        </div>
                        <span className="select-arr">
                          <i className="icon-arrow-sort" />
                        </span>
                        <div className="gender-border col-sm-12" />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="verification-block mt-0 p-0">
                <Row>
                  <Col xs={12}>
                    <FormGroup

                      className={`input-label-center input-transparent font-14 ${submitClick ?
                        selectedClub !== "" ?
                          'has-success' : 'has-error error'
                        : ''
                        }`}

                    >
                      <div className="select-gender">
                        <label style={formInputStyle.label}>
                          Favorite Club
                          </label>
                        <div className="genderStyle">
                          <Select
                            onChange={this.handleClubChange}
                            options={clubs}
                            classNamePrefix="secondary"
                            className="select-secondary minusML10"
                            placeholder="Select Club"
                            isSearchable={true}
                            isClearable={false}
                            value={selectedClub}
                            menuPlacement="auto"
                            theme={theme => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary: "#023320"
                              }
                            })}
                            getOptionLabel={(option) => option.club_name}
                            getOptionValue={(option) => option.id}
                          />
                        </div>
                        <span className="select-arr">
                          <i className="icon-arrow-sort" />
                        </span>
                        <div className="gender-border col-sm-12" />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <div className="verification-block">
                <Row>
                  <Col xs={12}>
                    <FormGroup

                      className={`input-label-center`}


                    >
                      <FloatingLabel
                        styles={formInputStyle}
                        id="website"
                        name="website"
                        value={website}
                        placeholder="Website / Social URL"
                        type="text"
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <div className="text-center">
                <Button
                  disabled={!isUserInfoValid ? true : false}
                  // onClick={this.onSubmit}
                  onClick={(e) => this.goTo(e, '3')}
                  className="btn btn-rounded signup-btn us-btn1"
                // type="submit"
                >
                  PROCEED
                  </Button>
              </div>
            </form>
          </div>
          <div className={`cp-tab ${activeTab === '3' ? 'active' : ''}`}>

            {/* <div className={'mem-tab-ar'}>

              {
                master_plans.map((plan, i) => {
                  return <div
                    key={i}
                    onClick={() => this.MembershipTabChange(plan.master_plan_id, i)}
                    className={`mem-tab ${memberShipTab === plan.master_plan_id ? 'active' : ''}`}
                  >
                    <span>{plan.plan_name}</span>
                  </div>
                })
              }

            </div> */}

            <div className={'mem-tab-body mem-slider-body'}>
            <div className="membership-slider">
            <Slider {...settings} >
              {
                master_plans.map((plan, i) => {
                  return <div
                    key={i}
                    className={`mem-tab-dv ${memberShipTab === plan.master_plan_id ? 'active' : ''}`}>
                    <div className="mem-inner-body">

                      <div className={'head-part'}>
                        <div className={'mm-hdng'}>{plan.plan_name}</div>
                        {

                          plan.sub_plan.length === 0 ?
                            plan.amount == 0 ?
                              <div className={'fr-h'}>FREE</div> :
                              <div>



                                {/* {
                                  plan.discount_percent != 0 ?
                                    <React.Fragment>
                                      <div className={'old-plan'}>
                                        <span>
                                          <i className={'icon-Rits'}></i>
                                          <strike>{plan.amount}</strike>
                                        </span>
                                      </div>

                                    </React.Fragment> : ''
                                } */}

                                
                                    <React.Fragment>
                                      <div className={'old-plan'}>
                                        <span>
                                          <i className={'icon-Rits'}></i>
                                          <strike>{plan.old_display_amount}</strike>
                                        </span>
                                      </div>

                                    </React.Fragment> 
                                <React.Fragment>
                                  <div className={'offered-plan'}>
                                    <span>
                                      <i className={'icon-Rits'}></i>
                                      <span className={'vl'}>{Number(plan.amount) - (Number(plan.amount) * Number(plan.discount_percent) / 100)}</span>
                                      <span className={'prd'}>Annual</span>
                                    </span>
                                  </div>
                                </React.Fragment>


                              </div> :

                            <div>

                              <div className={'prem-plan-types'}>

                                {
                                  plan.sub_plan.map((sp, idx) => {
                                    return <div
                                      key={idx}
                                      className={`pr-che ${subPlanType === sp.master_plan_id ? 'active' : ''}`}
                                      onClick={() => this.subPlanTypeChange(sp.master_plan_id)}
                                    >
                                      <span className={'lbl'}>{sp.plan_name}</span>

                                      {/* {
                                        sp.discount_percent !== '0' ?
                                          <span className={'vl old'}>
                                            <i className={'icon-Rits'}></i> <strike>{Number(sp.old_display_amount)}</strike> </span>
                                          : ''
                                      } */}
 
                                       
                                          <span className={'vl old'}>
                                            <i className={'icon-Rits'}></i> <strike>{Number(sp.old_display_amount)}</strike> </span>
                                          
                                      
                                      <span className={'vl'}>
                                      
                                        <i className={'icon-Rits'}></i> {Number(sp.amount) - (Number(sp.amount) * Number(sp.discount_percent) / 100)} </span>
                                      <span className={'checkie'}><img src={Images.CHECKED} /></span>
                                    </div>
                                  })
                                }

                              </div>
                            </div>


                        }
                      </div>
                      <div className={'feat-dv'}>

                        {
                          plan.master_plan_id === '1' ?
                            <ul className="feats">
                              <li>Regular Username </li>
                              <li>Limited social, gamification, livescore & wallet features</li>
                              <li>Eligible to Join free Groups</li>
                              <li>Eligible to Subscribe to free Channels </li>
                              <li>Eligible to participate in RFL Global Series</li>
                              <li>Lowest priority in RFL ranking tie</li>
                              <li>Can’t skip video ads</li>
                              <li>Ineligible to earn from watching ads</li>
                              <li>Ineligible to win cash prizes</li>
                              {/* <li>Lowest priority in ranking tie</li> */}
                              <li>Ineligible to join paid contest</li>
                            </ul>
                            :
                            plan.master_plan_id === '2' ?
                              <ul className="feats">
                                 <li>Freemium benefits and more</li>
                                  {/* <li>League Badges & Awards</li> */}
                                  <li className="highlight-feature">*Bold Username</li>
                                  <li >Skip video ads after 7sec</li>
                                  <li>Higher priority in ranking tie</li>
                                  <li>Eligible to win cash prizes</li>
                                  <li>Eligible to Join Private or Public contests</li>
                                  <li>Eligible to Join paid Groups</li>
                                  <li>Eligible to Subscribe to paid Channels</li>
                                  {/* <li>Higher priority in ranking tie</li>
                                  <li>Join paid contests</li> */}
                              </ul> :
                              plan.master_plan_id === '3' ?
                                <ul className="feats">
                                  <li>Premium privileges & more</li>
                                  <li ><img src={Images.P_PLUS} alt="" /> Premium+ badge</li>
                                  <li>Eligible to Get Verified badge</li>
                                  <li > Skip video Ads anytime</li>
                                  {/* <li >*Create Private Contest (entry fee)</li> */}
                                  <li>Highest priority in RFL ranking tie</li>
                                  <li>Eligible for AdEarn program (earn from watching Ads)</li>
                                  <li>Create/ Join Private & Public contests</li>
                                  <li>Earn Commissions for Entry fee contests</li>
                                  <li>
                                  Create paid group or Add payment feature in groups
                                  </li>
                                    {/* <li>Earn from watching Ads</li>
                                    <li>Highest priority in ranking tie</li>
                                    <li>Create Private & Public contest</li> */}
                                   <li >
                                  <div className="font-weight-bold">
                                  Create channel to Streams 
                                     </div>
                              
                                      <div className={'sub-feat'}>
                                      <div>Subscribers counter on Profile</div>
                                      <div>Enable free/ paid subscriptions </div>
                                      <div> Receive perks/ token from subscribers
                                      </div>
                                    </div>
                                  </li>

                                  <li>
                                   <div className="font-weight-bold">
                                   Profile Add-ons
                                     </div>
                                      <div className={'sub-feat'}>
                                      <div>Add Buttons e.g. [visit website], [visit store], [view catalogue] etc.</div>
                                      <div>Add People e.g. staff, players, artists, members etc.</div>
                                    </div>
                                  </li>
                                  <li>Account analytics/ insights</li>
                                  <li>Schedule Posts feature</li>
                                  {/* <li>Accept Tokens from subscribers to Streams </li>
                                  <li>Enable subscribers</li> */}
                                </ul> : ''
                        }

                        {/* <ul className={'feats'}>

                              {
                                Object.keys(JSON.parse(plan.features)).map((key, idx) => {
                                  if (JSON.parse(plan.features)[key] == '1') {
                                    return <li key={idx}>{key}</li>
                                  }
                                })
                              }
                            </ul> */}
                      </div>


                    </div>
                  </div>
                })
              }
              

              
            </Slider >
            </div>
              <div className={`mem-tab-dv ${memberShipTab === '5' ? 'active' : ''}`}>
                <div>
                  <div className={'mm-hdng'}>Freemium</div>
                  <div className={'fr-h'}>FREE</div>
                </div>
                <div className={'feat-dv'}>
                  <ul className={'feats'}>
                    <li>Free Sign Up</li>
                    <li>ReFSocial Features</li>
                    <li>No Cash Prizes</li>
                    <li>League Badges & Awards</li>
                    <li>Regular Username</li>
                  </ul>
                </div>
              </div>

              <div className={`mem-tab-dv ${memberShipTab === '6' ? 'active' : ''}`}>
                <div className={'mm-hdng'}>Premium Basic</div>

                <div className={'prem-plan-types'}>

                  <div className={`pr-che ${subPlanType === '1' ? 'active' : ''}`} onClick={() => this.premPlanTypeChange('1')}>
                    <span className={'lbl'}>By-weekly</span>
                    <span className={'vl'}>
                      <i>{userProfile.currency || ''}</i> 200
                        </span>
                    <span className={'checkie'}><img src={Images.CHECKED_W} /></span>
                  </div>

                  <div className={`pr-che ${subPlanType === '2' ? 'active' : ''}`} onClick={() => this.premPlanTypeChange('2')}>
                    <span className={'lbl'}>weekly</span>
                    <span className={'vl'}>
                      <i>{userProfile.currency || ''}</i> 400
                        </span>
                    <span className={'checkie'}><img src={Images.CHECKED_W} /></span>
                  </div>

                  <div className={`pr-che ${subPlanType === '3' ? 'active' : ''}`} onClick={() => this.premPlanTypeChange('3')}>
                    <span className={'lbl'}>monthly</span>
                    <span className={'vl'}>
                      <i>{userProfile.currency || ''}</i> 1500
                        </span>
                    <span className={'checkie'}><img src={Images.CHECKED_W} /></span>
                  </div>

                </div>

                <div className={'feat-dv'}>
                  <ul className={'feats'}>
                    <li>Free Sign Up</li>
                    <li>ReFSocial Features</li>
                    <li className={'bold'}>*Cash Prizes</li>
                    <li>League Badges & Awards</li>
                    <li className={'bold'}>Bold Username</li>
                    <li>*Eligible to Join I/O (paid contests)</li>
                    <li>*Skip Video Ad</li>
                  </ul>
                </div>


              </div>

              <div className={`mem-tab-dv ${memberShipTab === '8' ? 'active' : ''}`}>
                <div className={'mm-hdng'}>Premium Plus</div>

                <div className={'old-plan'}>
                  <span>
                    <i>{userProfile.currency || ''}</i>
                    <strike>19,200</strike>
                  </span>
                </div>

                <div className={'offered-plan'}>
                  <span>
                    <i>{userProfile.currency || ''}</i>
                    <span className={'vl'}>16,320</span>
                    <span className={'prd'}>Annual</span>
                  </span>
                </div>

                <div className={'feat-dv'}>
                  <ul className={'feats'}>
                    <li>Free Sign Up</li>
                    <li>ReFSocial Features</li>
                    <li className={'bold'}>*Cash Prizes</li>
                    <li>League Badges & Awards</li>
                    <li className={'bold'}>Bold Username</li>
                    <li><img src={Images.P_PLUS} style={{ width: 19 }} /> Premium + Badge</li>
                    <li>*Eligible to Join I/O (paid contests)</li>
                    <li>*Skip Video Ad</li>
                    <li>*Create Private Contest (entry fee)</li>
                  </ul>
                </div>

              </div>

            </div>
            

            {
              proceedMsg != "" &&
              <div className={'text-center mt-3 px-2'}><b>{proceedMsg}</b></div>
            }
            <div className={'pln-bt-ar'}>
              <button
                className={'plan-btn'}
                disabled={!isMembershipPlanValid ? true : false}
                onClick={proceedMsg == "" ? this.proceedToPayConfirm : this.choosePlan}
              >
                {proceedMsg == "" ? "CHOOSE MEMBERSHIP" : "Proceed"}
 
                {
                  currentShowPlan.discount_percent != undefined && currentShowPlan.discount_percent !== '0' ?
                    <span>{currentShowPlan.discount_percent}% Discount</span> : ''
                }
                {currentShowPlan.master_plan_id == '3' && <span className={'offer-m'}>1 Month Free</span>}

              </button>
            </div>

          </div>

        </Modal.Body>
      </Modal>
    );
  }
}
