import React from 'react';
import './App.scss';
import Config from './Config';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import Notification from "react-notify-toast";
import WSManager from "Helpers/WsManager";
import * as Constants from 'Constants/Constants'
import _ from 'lodash';
import * as NC from "Constants/NetworkingConstants";
import { Loader } from 'Components';
import Main from "Main";

import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import './assets/scss/style.scss';



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }



  componentWillMount() {

  }


  render() {


    return (
      <div className="App">
        <main className="Site">
          <div className="Site-content">
            <Router >
              <Notification options={{ zIndex: 99999 }} />
              <Switch>
                <Route exact component={Main} />
                <Redirect from="*" to="/" />
              </Switch>
            </Router>
          </div>
        </main>
      </div>
    );
  }
}

export default withTranslation()(App);
