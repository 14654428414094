import React from 'react';
import { Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Row, Col, Button } from 'reactstrap';
import Images from 'Constants/Images';
import { Header, Loader } from "Components";
import Validation from "Helpers/validation";
import FloatingLabel, { floatingStyles, focusStyles, inputStyles, labelStyles } from "floating-label-react";
import * as NC from "Constants/NetworkingConstants";

import WSManager from "Helpers/WsManager";
import { notify } from "react-notify-toast";

import OtpInput from "react-otp-input";
import "react-phone-number-input/style.css";


const md5 = require("md5");

const formInputStyle = {
    floating: {
        ...floatingStyles,
        color: "rgba(255, 255, 255, 0.6)",
        fontSize: "12px",
        borderBottomColor: "rgba(153, 153, 153, 0.5)",
        fontFamily: "MuliRegular"
    },
    focus: {
        ...focusStyles,
        borderColor: "rgba(153, 153, 153, 1)"
    },
    input: {
        ...inputStyles,
        borderBottomWidth: 1,
        borderBottomColor: "rgba(153, 153, 153, 0.5)",
        width: "100%",
        fontSize: "16px",
        color: "#fff",
        fontFamily: "MuliBold",
        padding: "16px 0px 10px",
        backgroundColor: "transparent"
    },
    label: {
        ...labelStyles,
        paddingBottom: "0px",
        marginBottom: "0px",
        width: "100%",
        fontSize: "12px",
        color: "rgba(255, 255, 255, 0.8)",
        fontFamily: "MuliRegular"
    }
};


class LoginModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            submitClick: false,
            isShowPassword: false,
            isLoading: false,
            posting: false,
            refLogin: false,
            ref_id: '',
            otp: '',
            formValid: false,
            sentOtp: false,
            isCompleted: false,
            secretQuesActive: false,
            secretQuestion: "",
            secretAnswer: ""
        }
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    showPassword = () => {
        this.setState({
            isShowPassword: !this.state.isShowPassword
        });
    }

    onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, this.IsFormValid);
    }

    IsFormValid() {
        const { username, password, ref_id, otp, refLogin, secretQuestion, secretAnswer } = this.state;
        var isValid = true;

        if (!refLogin) {
            if (Validation.validate("required", username) !== "success") {
                isValid = false;
            } else if (Validation.validate("required", password) !== "success") {
                isValid = false;
            }
        }

        if (refLogin) {
            if (ref_id === '') {
                isValid = false;
            }
            else if (otp === '' || otp.length !== 6) {
                isValid = false;
            }
            else if (secretQuestion === "") {
                isValid = false;
            }
            else if (secretAnswer === "") {
                isValid = false;
            }
        }

        this.setState({ formValid: isValid })
        return isValid;
    }


    onSubmit = (e) => {
        e.preventDefault();
        this.setState({
            submitClick: true,
        });
        if (this.IsFormValid()) {
            this.setState({
                isLoading: true,
                posting: true
            });

            let param = {
                device_type: NC.deviceType,
                ref_login: this.state.refLogin ? '1' : '0'
            }

            if (this.state.refLogin) {
                param['ref_id'] = this.state.ref_id;
                param['otp'] = this.state.otp;
                param['secret_question'] = this.state.secretQuestion;
                param['secret_question_answer'] = this.state.secretAnswer;
            }
            else {
                param['user_name'] = this.state.username;
                param['password'] = md5(this.state.password);
            }


            WSManager.Rest(NC.LOGIN, param).then(responseJson => {
                this.setState({ isLoading: false, posting: false });
                if (responseJson.response_code === NC.successCode) {
                    // this.props.IsLoginModalHide();
                    WSManager.setToken(responseJson.data.session_key);
                    WSManager.setProfile(responseJson.data.user_profile);
                    WSManager.setProfileCompleted(
                        responseJson.data.user_profile.is_profile_complete
                    );
                    
                    if(!responseJson.data.user_profile.secret_question){
                        this.props.IsLoginModalHide();
                        Header.secretAccessModalShow('login');
                    }

                    else if (responseJson.data.user_profile.is_profile_complete === 0) {
                        this.props.IsLoginModalHide();
                        Header.CompleteProfileShow();
                    }
                    else {

                        let appurls = ["refpay", "refleague", "reflive", "refsocial"];
                        if (this.props.isOpenFrom) {

                            if (appurls.includes(this.props.isOpenFrom)) {
                                WSManager.goToAppUrl(this.props.isOpenFrom);
                            } else {
                                window.location.href = this.props.isOpenFrom
                            }

                        } else {
                            window.location.reload();
                        }
                    }
                }
            });
        }
    }


    otpSubmit = (e) => {
        e.preventDefault();
        this.setState({
            submitClick: true,
        });
        let param = {
            ref_id: this.state.ref_id,
            otp: this.state.otp
        }


        WSManager.Rest(NC.VALIDATE_LOGIN_OTP, param).then(res => {
            if (res.response_code === NC.successCode) {

                if (!res.data.secret_question) {
                    notify.show("No secret question found. Please login with email, password", "error", 1000);
                    this.toggleLoginType()
                    return;
                }
                this.setState({
                    secretQuesActive: true,
                    submitClick: false,
                    secretQuestion: res.data.secret_question
                })
            }
        })

    }


    toggleLoginType = () => {

        this.setState({
            username: "",
            password: "",
            ref_id: "",
            otp: "",
            secretQuesActive: false,
            submitClick: false,
            refLogin: !this.state.refLogin,
            sentOtp: false

        }, this.IsFormValid)
    }




    onOtpChange = otp => {
        this.setState({ otp }, this.IsFormValid)
    }


    sendOtp = () => {
        if (this.state.ref_id === '') {
            notify.show('Please enter Ref Id', "error", 1000);
            return;
        };
        let param = {
            ref_id: this.state.ref_id
        }
        WSManager.Rest(NC.SEND_LOGIN_OTP, param).then(responseJson => {
            if (responseJson && responseJson.response_code === NC.successCode) {
                notify.show(responseJson.message, "success", 1000);
                this.setState({
                    sentOtp: true,
                    otp: ""
                });
            }
        })

    }

    ResendOtp = () => {

    }


    render() {


        const { IsLoginModalShow, IsLoginModalHide, isOpenFrom } = this.props;
        const {
            username,
            password,
            submitClick,
            isShowPassword,
            isLoading,
            posting,
            refLogin,
            ref_id,
            otp,
            sentOtp,
            formValid,
            secretQuestion, secretAnswer,
            secretQuesActive
        } = this.state;


        return (
            <React.Fragment>
                <Modal
                    isOpen={IsLoginModalShow}
                    toggle={IsLoginModalHide}
                    className='login-modal custom-modal-with-bg'
                >
                    <div className="modal-header-bg">
                        <a className="modal-close-btn" onClick={IsLoginModalHide}>
                            <i className="icon-close" />
                        </a>
                        <img src={Images.LOGO_PURPLE} alt="" />
                        <div className="modal-title fr-d-fn">For the fans</div>
                    </div>

                    <div className="modal-overlay" />
                    <form className="form-section" >
                        <ModalBody>




                            {
                                refLogin ?

                                    <div className={`lg-sec ref-login-sec active `}>

                                        {
                                            !secretQuesActive ?

                                                <React.Fragment>
                                                    <div className="verification-block">
                                                        <Row>
                                                            <Col xs={12}>
                                                                {/* {console.log(username, ref_id)} */}
                                                                <FormGroup
                                                                    className={`input-label-center ref_id_grp  ${submitClick ?
                                                                        Validation.validate("required", ref_id) == "success" ?
                                                                            'has-success' : 'has-error'
                                                                        : ''
                                                                        }`}
                                                                >
                                                                    <FloatingLabel
                                                                        styles={formInputStyle}
                                                                        id="ref_id"
                                                                        name="ref_id"
                                                                        value={ref_id || ''}
                                                                        placeholder="Ref ID"
                                                                        type="text"
                                                                        onChange={this.onChange}
                                                                    />
                                                                    <span className={'r-con'}>R-</span>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs={12} className="text-center">
                                                                <FormGroup>
                                                                    <a style={{ display: "inline-block" }}
                                                                        className="link-txt mt-0" onClick={() => this.sendOtp()}>
                                                                        {sentOtp ? 'Resend' : 'Send'}  OTP </a>
                                                                </FormGroup>
                                                            </Col>

                                                        </Row>


                                                    </div>

                                                    {
                                                        sentOtp ?
                                                            <div className="verification-block">
                                                                <Row>
                                                                    <Col xs={12} className="otp-field">
                                                                        <label style={formInputStyle.label}>
                                                                            OTP</label>
                                                                        <OtpInput
                                                                            autoComplete="off"
                                                                            shouldautofocus={true}
                                                                            numInputs={6}
                                                                            value={otp}
                                                                            onChange={this.onOtpChange}
                                                                            separator={<span />}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            : ''}
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <div className="verification-block secret-ques-bl">

                                                        <FormGroup
                                                            className={`input-label-center`}
                                                        >
                                                            <label>Secret Question</label>
                                                            <div className={'se-val'}>{secretQuestion || 'Hello world'}</div>
                                                        </FormGroup>


                                                    </div>


                                                    <div className="verification-block">

                                                        <FormGroup
                                                            className={`input-label-center  ${submitClick ?
                                                                Validation.validate("required", secretAnswer) == "success" ?
                                                                    'has-success' : 'has-error'
                                                                : ''
                                                                }`}
                                                        >

                                                            <FloatingLabel
                                                                styles={formInputStyle}
                                                                id="secretAnswer"
                                                                name="secretAnswer"
                                                                value={secretAnswer}
                                                                placeholder="Secret Answer"
                                                                type="text"
                                                                onChange={this.onChange}
                                                            />
                                                        </FormGroup>


                                                    </div>
                                                </React.Fragment>
                                        }








                                    </div>
                                    :
                                    <div className={`lg-sec simple-login-sec active`}>
                                        <div className="verification-block">
                                            <Row>
                                                <Col xs={12}>
                                                    <FormGroup
                                                        className={`input-label-center ${submitClick ?
                                                            Validation.validate("required", username) == "success" ?
                                                                'has-success' : 'has-error'
                                                            : ''
                                                            }`}

                                                    >
                                                        {console.log(username, ref_id)}
                                                        <FloatingLabel
                                                            styles={formInputStyle}
                                                            id="MobileNumber/Username"
                                                            name="username"
                                                            value={username || ""}
                                                            placeholder="Mobile Number / Username"
                                                            type="text"
                                                            onChange={this.onChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                {/* <button onClick={()=>this.setState({username:''})}>fdaf</button> */}
                                            </Row>
                                        </div>
                                        <div className="verification-block">
                                            <Row>
                                                <Col xs={12}>
                                                    <FormGroup
                                                        className={`input-label-center ${submitClick ?
                                                            Validation.validate("required", password) == "success" &&
                                                                Validation.validate("password", password) == "success" ?
                                                                'has-success' : 'has-error'
                                                            : ''
                                                            }`}
                                                    >
                                                        <FloatingLabel
                                                            styles={formInputStyle}
                                                            id="password"
                                                            value={password || ''}
                                                            name="password"
                                                            placeholder="Password"
                                                            type={isShowPassword ? "text" : "password"}
                                                            onChange={this.onChange}
                                                        />
                                                    </FormGroup>
                                                    <a

                                                        className="hidden-align eye-btn"
                                                        onClick={() => this.showPassword()}
                                                    >
                                                        {this.state.isShowPassword ? (
                                                            <img src={Images.EYE_DARK_ICON} width="23px" alt='' className="opacity" />
                                                        ) : (
                                                                <img src={Images.EYE_CANCEL_DARK_ICON} alt='' width="23px" />
                                                            )}
                                                    </a>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                            }
                            <div className="bottom-fixed">

                                {!refLogin &&
                                    <div className="text-center">
                                        <a

                                            className="forgot-pwd-link"
                                            onClick={() => {
                                                Header.ForgotPasswordShow("login");
                                                IsLoginModalHide();
                                            }}
                                        >Forgot password? </a>
                                    </div>

                                }

                                {
                                    !secretQuesActive && refLogin ?
                                        <div className="text-center">

                                            <Button
                                                disabled={posting || ref_id === "" || otp === "" || otp.length < 6}
                                                className="btn btn-rounded btn-primary "
                                                onClick={this.otpSubmit}
                                                type="submit"
                                            >VERIFY</Button>
                                        </div> :
                                        <div className="text-center">
                                            <Button
                                                disabled={posting || !formValid}
                                                className="btn btn-rounded btn-primary "
                                                onClick={this.onSubmit}
                                                type="submit"
                                            >Log in</Button>
                                        </div>
                                }


                                {
                                    !refLogin &&
                                    <div className={'lg-wd-ref'}>
                                        <div className={'hdng_1'}>
                                            <span className={'ln l'}></span>
                                            <span className={'tx'}>or Login with</span>
                                            <span className={'ln r'}></span>
                                        </div>
                                        <div className={'ref-bt-grp'}>
                                            <Button
                                                className="btn ref-id-btn "
                                                onClick={this.toggleLoginType}
                                            // type="submit"
                                            >
                                                <img src={Images.LOGO_PURPLE} />{refLogin ? 'Email/Username' : 'REF ID'}
                                            </Button>
                                        </div>

                                    </div>
                                }

                                {
                                    !refLogin ?
                                        <div className="text-center user-help">
                                            New ReF?{" "}
                                            <a

                                                onClick={() => {

                                                    IsLoginModalHide()
                                                    Header.SignupModalShow("login")
                                                }
                                                }
                                            >Sign Up</a>
                                        </div> :
                                        <div className="text-center user-help">
                                            Back to &nbsp;
                                            <a

                                                onClick={this.toggleLoginType}
                                            >Login</a>
                                        </div>
                                }


                            </div>
                        </ModalBody>
                    </form>
                </Modal>
            </React.Fragment>
        )
    }
}

LoginModal.defaultProps = {
    isOpen: false
}

export default LoginModal;