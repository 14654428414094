
import * as NC from "./NetworkingConstants";

const Images = {
    LOGO: NC.getImageBaseUrl('logo.png'),
    LOGO_PBL: NC.getImageBaseUrl('logo.png'),
    LOGO_BLACK: NC.getImageBaseUrl('logo-black.png'),
    LOGO_PURPLE: NC.getImageBaseUrl('logo-purple.png'),
    USER_IC :NC.getImageBaseUrl('user.png'),
    REFLIVE :NC.getImageBaseUrl('reflive.svg'),
    REFPAY :NC.getImageBaseUrl('refpay.svg'),
    REFPREDICTOR :NC.getImageBaseUrl('refpredictor.svg'),
    REFSOCIAL :NC.getImageBaseUrl('refsocial.svg'),
    VISSEL: NC.getImageBaseUrl('vissel.png'),
    EYE_ICON: NC.getImageBaseUrl('eye.png'),
    EYE_CANCEL_ICON: NC.getImageBaseUrl('eye-cancel.png'),
    EYE_DARK_ICON: NC.getImageBaseUrl('eye-dark.png'),
    EYE_CANCEL_DARK_ICON: NC.getImageBaseUrl('eye-cancel-dark.png'),
    EYE_CANCEL_DARK_GREEN_ICON: NC.getImageBaseUrl('eye-cancel-dark-green.png'),
    WHATSAPP_IMG: NC.getImageBaseUrl('whatsapp.png'),
    TEXTMSG_IMG: NC.getImageBaseUrl('message.png'),
    WALLET: NC.getImageBaseUrl('wallet.svg'),
    USER: NC.getImageBaseUrl('user.svg'),
    CHECKED: NC.getImageBaseUrl('checked.svg'),
    CHECKED_W: NC.getImageBaseUrl('checked-w.svg'),
    DEFAULT_USER: NC.getImageBaseUrl('default-user.png'),
    CONGRATS_BG: NC.getImageBaseUrl('congrats-bg.png'),
    CALENDAR: NC.getImageBaseUrl('calendar.svg'),
    P_PLUS: NC.getImageBaseUrl('PlusImg.png'),
    REFLIVE_B :NC.getImageBaseUrl('reflive-b.svg'),
    REFPAY_B :NC.getImageBaseUrl('refpay-b.svg'),
    REFPREDICTOR_B :NC.getImageBaseUrl('refpredictor-b.svg'),
    REFSOCIAL_B :NC.getImageBaseUrl('refsocial-b.svg'),
    REFLIVE_BIG :NC.getImageBaseUrl('ReFLive1.svg'),
    REFPAY_BIG :NC.getImageBaseUrl('ReFPay4.svg'),
    REFPREDICTOR_BIG :NC.getImageBaseUrl('ReFLeague1.svg'),
    REFSOCIAL_BIG :NC.getImageBaseUrl('ReFSocial1.svg'),
    MEMBER_IC1 :NC.getImageBaseUrl('ic1.svg'),
    MEMBER_IC2 :NC.getImageBaseUrl('ic2.svg'),
    MEMBER_IC3 :NC.getImageBaseUrl('ic3.svg'),
    MEMBER_IC4 :NC.getImageBaseUrl('ic4.svg'),
    HERO_BANNER :NC.getImageBaseUrl('hero-banner.png'),
    REF_LOGO :NC.getImageBaseUrl('ref-logo.png'),
    AWARDS_TROPHY :NC.getImageBaseUrl('award-trophy.png'),
    FIXTURE :NC.getImageBaseUrl('fixture.png'),
    LOGIN_SIGNUP :NC.getImageBaseUrl('login-signup.png'),
    POINTS_RANKS :NC.getImageBaseUrl('points-ranks.png'),
    REDEEM_PRIZE :NC.getImageBaseUrl('redeem-prize.png'),
    FREEMIUM :NC.getImageBaseUrl('freemium.svg'),
    PREMIUM :NC.getImageBaseUrl('premium.svg'),
    PREMIUM_PLUS :NC.getImageBaseUrl('premium_plus.svg'),
    LEFT_ARROW :NC.getImageBaseUrl('left.png'),
    RIGHT_ARROW :NC.getImageBaseUrl('right.png'),
    USER2 :NC.getImageBaseUrl('user.png'),
    SCREEN1 :NC.getImageBaseUrl('1.png'),
    SCREEN2 :NC.getImageBaseUrl('2.png'),
    SCREEN3 :NC.getImageBaseUrl('3.png'),
    SCREEN4 :NC.getImageBaseUrl('4.png'),
    SCREEN5 :NC.getImageBaseUrl('5.png'),
    SCREEN6 :NC.getImageBaseUrl('6.png'),
    SCREEN7 :NC.getImageBaseUrl('7.png'),
    SCREEN8 :NC.getImageBaseUrl('8.png'),
    SCREEN9 :NC.getImageBaseUrl('9.png'),
    SCREEN10 :NC.getImageBaseUrl('10.png'),
    COMING_SOON :NC.getImageBaseUrl('coming-soon-img.png'),
    BIG_NOTIFICATION_BELL: NC.getImageBaseUrl('big_notification_bell.svg'), 
}

export default Images;