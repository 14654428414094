import React from 'react';
import './App.scss';
import Config from './Config';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import Notification from "react-notify-toast";
import WSManager from "Helpers/WsManager";
import * as Constants from 'Constants/Constants'
import _ from 'lodash';
import * as NC from "Constants/NetworkingConstants";
import { Loader, Footer } from 'Components';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.scss';
import Home from "Views/Home";
import MembershipPlan from "Views/MembershipPlan";
import Notifications from './Views/Notification';
import ContactUs from "Views/ContactUs";
import ScrollToTop from 'react-router-scroll-top';

const queryString = require('query-string');

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }



  componentWillMount() {


    let location = this.props.location;
    const parsed = queryString.parse(location.search);

    if (parsed.logout && Object.hasOwnProperty.bind(parsed)('logout')) {
      WSManager.logout();
      this.props.history.push('/');
    }


    let masterData = WSManager.getMasterData();
    if (masterData) {
      this.setMasterDataConst(masterData);
    }

    this.callWSGetMasterData();
    if (WSManager.loggedIn()) {
      Constants.SetUserBalance.callBalanceApi();
    }
  }

  callWSGetMasterData = () => {
    WSManager.Rest(NC.MASTER_DATA).then(responseJson => {
      if (responseJson.response_code === NC.successCode) {
        WSManager.setMasterData(responseJson.data);
        this.setMasterDataConst(responseJson.data);
        this.setState({ loading: false })
      }
    });
  }

  setMasterDataConst = (masterData) => {
    if (!_.isUndefined(masterData) && masterData.languages.length > 0) {
      let tmpArray = [];
      _.map(masterData.languages, (item, idx) => {
        tmpArray.push({ label: item, value: item });
      });
      Constants.SetValues.language(tmpArray);
    }

    if (masterData.networks.length > 0) {
      let tmpArray = [];
      _.map(masterData.networks, (item, idx) => {
        tmpArray.push({ label: item, value: item });
      });
      Constants.SetValues.networks(tmpArray);
    }

    if (masterData.banks.length > 0) {
      let tmpArray = [];
      _.map(masterData.banks, (item, idx) => {
        tmpArray.push({ label: item, value: item });
      });
      Constants.SetValues.banks(tmpArray);
    }

    if (masterData.leagues.length > 0) {
      Constants.SetValues.leagues(masterData.leagues);
    }

    if (masterData.scoring_rules.length > 0) {
      Constants.SetValues.scoring_rules(masterData.scoring_rules);
    }

    Constants.SetValues.membership_plans(masterData.membership_plans);
    Constants.SetValues.clubs(masterData.club);
    Constants.SetValues.current_round(masterData.current_round);
    Constants.SetValues.set_sports(masterData.sports);

    if (masterData.categories.length > 0) {
      Constants.SetValues.set_categories(masterData.categories);
    }


  }


  render() {


    // const PrivateRoute = ({ component: Component, ...rest }) => (
    //   <Route
    //     {...rest}
    //     render={props =>
    //       WSManager.loggedIn() === true ? (
    //         <Component {...props} />
    //       ) : (
    //           <Redirect
    //             to={{
    //               pathname: "/",
    //               state: { from: props.location }
    //             }}
    //           />
    //         )
    //     }
    //   />
    // );


    const { match } = this.props;

    if (this.state.loading) {
      return <Loader />
    }

    return (
            <Router >
      <div className="App">
        <main className="Site">
          <div className="Site-content">
              <Notification options={{ zIndex: 99999 }} />
              <ScrollToTop>
                <Switch>
                  <Route path="/" component={Home} exact />
                  <Route path="/membership-plan" component={MembershipPlan} exact />
                  <Route path="/contact" component={ContactUs} exact />
                  <Route path="/notification" component={Notifications} exact />
                  <Redirect from="*" to="/" />
                </Switch>
              </ScrollToTop>

              <Footer />
          </div>


        </main>

      </div>
            </Router>
    );
  }
}

export default withTranslation()(Main);
