import React from 'react';
import { Row, Col, Modal, Button } from "react-bootstrap";
import Validation from "Helpers/validation";
import WSManager from "Helpers/WsManager";
import * as NC from "Constants/NetworkingConstants";
import { notify } from "react-notify-toast";


class SecretAccessModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            secretQuestion: "",
            secretAnswer: "",
            formValid: false,
            posting:false
        }
    }


    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        }, this.IsFormValid)
    }


    IsFormValid() {
        const {
            secretQuestion,
            secretAnswer
        } = this.state;

        var isValid = true;

        if (secretQuestion === "" || secretQuestion.trim() === "" ||  Validation.validate("secret_ques", secretQuestion) !== "success") {
            isValid = false;
        }
        else if (secretAnswer === "" || secretAnswer.trim() === "" ||  Validation.validate("secret_ans", secretAnswer) !== "success") {
            isValid = false;
        }

        this.setState({ formValid: isValid })
        return isValid;
    }



    submitHandler = (e) => {

        if(!this.IsFormValid) return;

        let param = {
            secret_question: this.state.secretQuestion.trim(),
            secret_question_answer: this.state.secretAnswer.trim()
        }

        this.setState({posting:true})

        WSManager.Rest(NC.UPDATE_SECRET_QUESTION, param).then(responseJson => {
            if (responseJson.response_code === 200) {
                notify.show('Your secret details updated successfully!', "success", 5000);
                this.props.onHide()

                setTimeout(() =>{
                    window.location.reload();
                }, 1000)
               
               
            }else{
                this.setState({posting:false})
            }
        })
    }

    render() {

        const { show, onHide } = this.props;


        const { secretQuestion, secretAnswer, formValid, posting } = this.state;

        return (
            <React.Fragment>

                <Modal
                    show={show}
                    // onHide={onHide}
                    dialogClassName="secret-access-modal"
                >

                    <Modal.Body>
                        <div className={'heading'}>Your secure access</div>

                        <div className={'f-body'}>

                            <Row>
                                <Col xs={12}>
                                    <div className={'form-group'}>
                                        <label>Secret Question</label>
                                        <input
                                            type={'text'}
                                            placeholder={'7 words max'}
                                            name={'secretQuestion'}
                                            value={secretQuestion || ""}
                                            onChange={this.handleChange}
                                            className={secretQuestion !== "" && Validation.validate("secret_ques", secretQuestion) !== "success" ? 'error' : ''}
                                        />
                                        <div className={'note'}>Eg. A personal question that requires an honest answer that only you can answer correctly</div>
                                    </div>
                                </Col>

                                <Col xs={12}>
                                    <div className={'form-group'}>
                                        <label>Secret Answer</label>
                                        <input
                                            type={'text'}
                                            placeholder={'3 words max'}
                                            name={'secretAnswer'}
                                            value={secretAnswer || ""}
                                            onChange={this.handleChange}
                                            className={secretAnswer !== "" && Validation.validate("secret_ans", secretAnswer) !== "success" ? 'error' : ''}
                                        />
                                        <div className={'note'}>Eg. Provide an honest answer that you won’t forget anyday, anytime</div>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <Button
                                        className={'btn btn-rounded submit-btn'}
                                        disabled={!formValid || posting ? true : false}
                                        onClick={this.submitHandler}
                                    >SUBMIT</Button>
                                </Col>


                            </Row>


                        </div>

                    </Modal.Body>
                </Modal>

            </React.Fragment>
        )
    }
}

export default SecretAccessModal;