import React from 'react';
import WSManager from "Helpers/WsManager";
import * as NC from "Constants/NetworkingConstants";
import { Header } from 'Components';
import { Col, Container, Row } from 'reactstrap';
import Images from 'Constants/Images';
import Slider from "react-slick";
import { Accordion } from 'Components';
import Typewriter from 'typewriter-effect';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAccord: 1
        }
    }


    componentDidMount() {
        // WSManager.Rest(NC.SIGNUP, {})
        // .then(res => {
        //     console.log(res)
        // })
    }

    toggleAccord = key => {
        this.setState({ selectedAccord: this.state.selectedAccord == key ? null : key })
    }



    goTo = (key, page) => {
        if (WSManager.loggedIn()) {
            WSManager.goToAppUrl(key, page)
        } else {
            Header.LoginModalShow()
        }
    }

    render() {

        const { selectedAccord } = this.state;
        function CustomArrow(props) {
            const { className, style, onClick } = props;
            return (
                <div
                    className={className}
                    style={{ ...style }}
                    onClick={onClick}
                >
                    {
                        props.icon ?
                            <span className={`${props.icon}`}></span> :
                            <span className={`${props.direction == 'left' ? 'icon-left-arrow' : 'icon-right-arrow'} `}></span>
                    }


                </div>
            );
        }


        function CustomArrow2(props) {
            const { className, style, onClick } = props;
            return (
                <div
                    className={className}
                    style={{ ...style }}
                    onClick={onClick}
                >
                    <span >
                        <img src={`${props.direction == 'left' ? Images.LEFT_ARROW : Images.RIGHT_ARROW} `} />
                    </span>

                </div>
            );
        }
        var FeatureSliderSettings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            // prevArrow: (props) => <span onClick={props.onClick}><img src={Images.LEFT_ARROW} /></span>,
            prevArrow: <CustomArrow2 direction={'left'} />,
            nextArrow: <CustomArrow2 direction={'right'} />,
        };

        var membershipSliderSettings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },

            ]
        }

        var allSizeSliderSettings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },

            ]
        }


        var floatFeatureSliderSettings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            prevArrow: <CustomArrow direction={'left'} icon={'fa fa-angle-left'} />,
            nextArrow: <CustomArrow direction={'right'} icon={'fa fa-angle-right'} />,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },

            ]
        }


        return (
            <React.Fragment>
                <section className={'site-container home-sec'}>
                    <Header {...this.props} />

                    {/* <div className={'hm-strip'}>Home of the fans</div> */}


                    <section className={'hero-sec'} style={{ backgroundImage: `url(${Images.HERO_BANNER})` }}>
                        <Container className={''}>
                            <h1>Home of the fans</h1>

                            <button
                                onClick={() => this.goTo('refleague', `topage=dashboard/0`)}
                            >JOIN NOW</button>




                            {/* <h6> <Typewriter
                                options={{
                                    strings: ['Connect, Engage & Earn rewards in a social & responsible way.'],
                                    autoStart: true,
                                    loop: true,
                                    changeDeleteSpeed: 1,

                                }}

                            /></h6> */}

                            <h6> <Typewriter
                                onInit={(typewriter) => {

                                    typewriter
                                        .typeString('Connect, Engage & Earn rewards in a social & responsible way.')
                                        .pauseFor(1000)
                                        .deleteAll(1)
                                        // .changeDeleteSpeed(50000)
                                        .start();
                                }}

                                options={{
                                    autoStart: true,
                                    loop: true,
                                }}
                            /></h6>
                        </Container>

                    </section>


                    <section className={'product-sec '}>

                        <Container className={'cmn-site-container'}>


                            <h2 className={'cmn-head'}>Your Ultimate Membership</h2>
                            <h5 className={'cmn-subhead'}>One account to access everything fans need in one place.</h5>

                            <Row className={'prod-row'}>

                                <Slider {...membershipSliderSettings}>
                                    <Col xs={6} md={3}>
                                        <div className={'prod-box refsocial'}>
                                            <div className={'wrap'}>
                                                <div>
                                                    <img src={Images.REFSOCIAL_BIG} />
                                                    <h3>ReFSocial</h3>
                                                    <p>Make meaningful connections with other fans, clubs, gamers, celebrities, idols etc.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} md={3}>
                                        <div className={'prod-box refleague'}>
                                            <div className={'wrap'}>
                                                <div>
                                                    <img src={Images.REFPREDICTOR_BIG} />
                                                    <h3>ReFLeague</h3>
                                                    <p>Compete with family, friends and rivals for fun, fame & fortune in a social and responsible way.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} md={3}>
                                        <div className={'prod-box reflive'}>
                                            <div className={'wrap'}>
                                                <div>
                                                    <img src={Images.REFLIVE_BIG} />
                                                    <h3>ReFLive</h3>
                                                    <p>Keep tabs on every action that is important to you- anyhow, anywhere, anytime.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} md={3}>
                                        <div className={'prod-box refpay'}>
                                            <div className={'wrap'}>
                                                <div>
                                                    <img src={Images.REFPAY_BIG} />
                                                    <h3>ReFPurse</h3>
                                                    <p>As simple as a chat; transact with whoever, whenever, wherever in the world on this platform.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Slider>
                            </Row>

                        </Container>
                    </section>

                    <section className={'member-sec bg-grey'}>

                        <Container className={'cmn-site-container'}>
                            <h2 className={'cmn-head'}>All Sizes Fit One</h2>
                            <h5 className={'cmn-subhead'}>You have every right to be whoever you want to be. Make your preferred membership choice, even if you'd like it free forever!</h5>


                            <Row className={'mem-row'}>

                                <Slider {...allSizeSliderSettings}>
                                    {/* <Col xs={6} md={3}> */}
                                    <div className={'mem-box'}>
                                        <img src={Images.FREEMIUM} />
                                        <h5>Freemium</h5>
                                        <p>Enjoy our services to the fullest of the limits, forever! No free trials for limited period. While it is sufficient, there's more. Don't worry, you can always upgrade anytime.</p>
                                    </div>
                                    {/* </Col> */}
                                    {/* <Col xs={6} md={3}> */}
                                    <div className={'mem-box'}>
                                        <img src={Images.PREMIUM} />
                                        <h5>Premium</h5>
                                        <p>Embolden your passion! Enjoy additional benefits & privileges across all our services with flexible bi-weekly/ weekly/ monthly membership subscriptions.</p>
                                    </div>
                                    {/* </Col> */}
                                    {/* <Col xs={6} md={3}> */}
                                    <div className={'mem-box'}>
                                        <img src={Images.PREMIUM_PLUS} />
                                        <h5>Premium+</h5>
                                        <p>Get maximum premium benefits & privileges across all our services for one-time annual membership subscription. Holy grail?! We call it the Ultimate Membership.</p>
                                    </div>
                                    {/* </Col> */}
                                </Slider>
                            </Row>
                        </Container>

                    </section>

                    <section className={'feat-sec'}>

                        <h2 className={'cmn-head'}>Interesting Features</h2>
                        <h5 className={'cmn-subhead'}>Create new experiences with existing technologies via ReFSocial. Let's test the limits.</h5>


                        <div className={'feat-slider-area'}>

                            {/* <Container> */}
                            <Slider {...FeatureSliderSettings}>
                                <div className={'feat-slide'}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <div className={'cap-box'}>
                                                <h2>ReFID</h2>
                                                <p>Create a unified online identity to access your accounts on ReFSocial & other platforms.</p>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <div className={'r-main-ar'}>
                                                <img src={Images.SCREEN1} className={'main-img'} />
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                                <div className={'feat-slide'}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <div className={'cap-box'}>
                                                <h2>Member Profile</h2>
                                                <p>Create meaningful connections with people that matter to you.</p>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <div className={'r-main-ar'}>
                                                <img src={Images.SCREEN2} className={'main-img'} />
                                            </div>

                                        </Col>
                                    </Row>
                                </div>

                                <div className={'feat-slide'}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <div className={'cap-box'}>
                                                <h2>Timeline</h2>
                                                <p>Stay in touch with your world & the world around you.</p>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <div className={'r-main-ar'}>
                                                <img src={Images.SCREEN3} className={'main-img'} />
                                            </div>

                                        </Col>
                                    </Row>
                                </div>

                                <div className={'feat-slide'}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <div className={'cap-box'}>
                                                <h2>Individual & Group Messaging</h2>
                                                <p>Unique individual & free/ paid groups chat features.</p>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <div className={'r-main-ar'}>
                                                <img src={Images.SCREEN4} className={'main-img'} />
                                            </div>

                                        </Col>
                                    </Row>
                                </div>

                                <div className={'feat-slide'}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <div className={'cap-box'}>
                                                <h2>Group Hangout</h2>
                                                <p>Hangout with your friends & fans using text, audio & video without language barrier.</p>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <div className={'r-main-ar'}>
                                                <img src={Images.SCREEN5} className={'main-img'} />
                                            </div>

                                        </Col>
                                    </Row>
                                </div>

                                <div className={'feat-slide'}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <div className={'cap-box'}>
                                                <h2>Streams & Voices</h2>
                                                <p>Create & subscribe to free/ paid video & audio channels.</p>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <div className={'r-main-ar'}>
                                                <img src={Images.SCREEN6} className={'main-img'} />
                                            </div>

                                        </Col>
                                    </Row>
                                </div>


                                <div className={'feat-slide'}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <div className={'cap-box'}>
                                                <h2>ReFLeague</h2>
                                                <p>Compete with other fans for bragging rights & prizes.</p>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <div className={'r-main-ar'}>
                                                <img src={Images.SCREEN7} className={'main-img'} />
                                            </div>

                                        </Col>
                                    </Row>
                                </div>

                                <div className={'feat-slide'}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <div className={'cap-box'}>
                                                <h2>Public & Private Contests</h2>
                                                <p>Create & join free/ paid contests for friends, colleagues, followers, customers etc.</p>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <div className={'r-main-ar'}>
                                                <img src={Images.SCREEN8} className={'main-img'} />
                                            </div>

                                        </Col>
                                    </Row>
                                </div>


                                <div className={'feat-slide'}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <div className={'cap-box'}>
                                                <h2>ReFPurse Wallet </h2>
                                                <p>Seamless in-app transactions via digital wallet.</p>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <div className={'r-main-ar'}>
                                                <img src={Images.SCREEN9} className={'main-img'} />
                                            </div>

                                        </Col>
                                    </Row>
                                </div>


                                <div className={'feat-slide'}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <div className={'cap-box'}>
                                                <h2>Referral</h2>
                                                <p>Invite friends to earn referral points and rewards.</p>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <div className={'r-main-ar'}>
                                                <img src={Images.SCREEN10} className={'main-img'} />
                                            </div>

                                        </Col>
                                    </Row>
                                </div>




                            </Slider>
                            {/* </Container> */}
                        </div>

                    </section>



                    <section className={'feature-sec'}>

                    <h2 className={'cmn-head'}>Fans don't just support, they play!</h2>

                        <div className={'circle-wrap'}>

                            <div className={'bg-circle1'}></div>
                            <div className={'bg-circle2'}></div>

                            <div className={'logo-fr'}><img src={Images.LOGO_PURPLE} /></div>

                            <div className={'ft-ul desktop'}>


                                <div className={'ft-l login-signup'}>
                                    <div className={'ft-box'}>
                                        <div className={'img-circle'}><img src={Images.LOGIN_SIGNUP} /></div>
                                        <span>Sign Up/ Log In</span>
                                    </div>
                                </div>
                                <div className={'ft-l fixture'}>
                                    <div className={'ft-box'}>
                                        <div className={'img-circle'}><img src={Images.FIXTURE} /></div>
                                        <span>Play Selected <br />Fixture</span>
                                    </div>
                                </div>
                                <div className={'ft-l score-rank'}>
                                    <div className={'ft-box'}>
                                        <div className={'img-circle'}><img src={Images.POINTS_RANKS} /></div>
                                        <span>Score Points & Ranks</span>
                                    </div>
                                </div>
                                <div className={'ft-l redeem-prize'}>
                                    <div className={'ft-box'}>
                                        <div className={'img-circle'}><img src={Images.REDEEM_PRIZE} /></div>
                                        <span>Redeem Prizes</span>
                                    </div>
                                </div>
                                <div className={'ft-l awards'}>
                                    <div className={'ft-box'}>
                                        <div className={'img-circle'}><img src={Images.AWARDS_TROPHY} /></div>
                                        <span>ReFLeague</span>
                                    </div>
                                </div>
                            </div>

                            <div className={'ft-ul mobile'}>


                                <Slider {...floatFeatureSliderSettings}>
                                    <div className={'ft-l login-signup'}>
                                        <div className={'ft-box'}>
                                            <div className={'img-circle'}><img src={Images.LOGIN_SIGNUP} /></div>
                                            <span>Sign Up/ Log In</span>
                                        </div>
                                    </div>
                                    <div className={'ft-l fixture'}>
                                        <div className={'ft-box'}>
                                            <div className={'img-circle'}><img src={Images.FIXTURE} /></div>
                                            <span>Play Selected Fixture</span>
                                        </div>
                                    </div>
                                    <div className={'ft-l score-rank'}>
                                        <div className={'ft-box'}>
                                            <div className={'img-circle'}><img src={Images.POINTS_RANKS} /></div>
                                            <span>Score Points & Ranks</span>
                                        </div>
                                    </div>
                                    <div className={'ft-l redeem-prize'}>
                                        <div className={'ft-box'}>
                                            <div className={'img-circle'}><img src={Images.REDEEM_PRIZE} /></div>
                                            <span>Redeem Prizes</span>
                                        </div>
                                    </div>
                                    <div className={'ft-l awards'}>
                                        <div className={'ft-box'}>
                                            <div className={'img-circle'}><img src={Images.AWARDS_TROPHY} /></div>
                                            <span>ReFLeague</span>
                                        </div>
                                    </div>
                                </Slider>
                            </div>

                        </div>



                    </section>

                    <section className={'gl-ser-section'}>

                        <Container className={'cmn-site-container'}>

                            <h2 className={'cmn-head'}>Global Series</h2>
                            <p className={'desc'}>Take the Ultimate fan's challenge. Compete with other fans globally to be crowned the RFL Global Series Champion &
                                lift the most coveted Crystal Ball Trophy! Lots of excitement & prizes throughout your journey. Wawu!!</p>

                            <button className={'join-btn'} onClick={() => this.goTo('refleague', `topage=dashboard/0`)}>Join in</button>

                        </Container>


                    </section>



                    <section className={'faq-section'}>

                        <Container className={'cmn-site-container'}>

                            <h2 className={'cmn-head'}>FAQs</h2>

                            <Accordion
                                open={selectedAccord == 1 ? true : false}
                                heading={'What is ReFSocial?'}
                                toggle={() => this.toggleAccord(1)}
                            >
                                <div className={'bdy'}>
                                    <div>
                                        ReFSocial is a membership-based social networking service that connects, engages and rewards fans.
                                    </div>
                                    <div>
                                        From social networking to gaming, news, live scores & statistics, to payment; one membership account for everything fans need in one place.
                                    </div>
                                    <div>
                                        A platform where fans build meaningful connections and mutually beneficial relationships.
                                    </div>
                                </div>
                            </Accordion>


                            <Accordion
                                open={selectedAccord == 2 ? true : false}
                                heading={'Why ReFSocial?'}
                                toggle={() => this.toggleAccord(2)}
                            >
                                <div className={'bdy'}>
                                    <div>
                                        Over a decade ago, we dared to question why some successful business ecosystems are built around the fans,
                                        yet the fans remain the least remunerated, if at all, any financial gain beyond our emotions.
                                    </div>
                                    <div>
                                        Imagine having more control of your time and being able to monetize your passion with the tools that you have, in places you’ve been,
                                        with people you know... <b>ReFSocial</b> empowers you to create new experiences and connections that makes you feel fulfilled.
                                    </div>
                                    <div>
                                        <b>ReFSocial</b> enables you to earn from watching Ads, creating free/ paid channels, groups or contests, whether it’s fantasy,
                                        esports, sports, news, music, movie, art etc., whichever fan you are.
                                    </div>
                                    <div>
                                        <b>ReFSocial</b> provides the avenue for fans with common interest to aggregate their voices and harness the collective power of
                                        fandom to drive positive causes, globally.
                                    </div>


                                </div>
                            </Accordion>

                            <Accordion
                                open={selectedAccord == 3 ? true : false}
                                heading={'Who is a Ref?'}
                                toggle={() => this.toggleAccord(3)}
                            >
                                <div className={'bdy'}>
                                    <div >
                                        A <b>Ref</b> is a short form of referee; a mediator, someone of good conduct and credentials, who is in charge,
                                    responsible and takes responsibility. It won’t be surprising that these are the expectations of a good fan too.
                                    Being a fan is being a ref. <b>ReFSocial</b> members are referred to as <b>Refs</b>.
                                    </div>
                                    <div>
                                        Ready to be a <b>Ref</b>? <span className={'join-fq'} onClick={() => this.goTo('refleague', `topage=dashboard/0`)}>JOIN NOW</span>
                                    </div>
                                </div>
                            </Accordion>

                            <Accordion
                                open={selectedAccord == 4 ? true : false}
                                heading={'How does the Ref membership work?'}
                                toggle={() => this.toggleAccord(4)}
                            >
                                <div className={'bdy'}>
                                    <div>
                                        <b>ReFSocial</b> membership is about committing to the positive attributes of a ref while using the platform to make the world a better place.
                                   </div>
                                    <div>
                                        The Ref membership simply requires joining the community by <span className={'join-fq'} onClick={() => this.goTo('refleague', `topage=dashboard/0`)}>Signing up</span> and choosing between our 3 membership options that may suit you;
                                   </div>

                                    <div>
                                        <div>
                                            <b>Freemium</b> (free; no payment required forever!)
                                       </div>
                                        <div>
                                            <b>Premium</b> (bi-weekly, weekly, monthly subscription) </div>
                                        <div>
                                            <b>Premium+</b> (one-time annual subscription)
                                        </div>
                                    </div>

                                    <div>
                                        Your Ref membership gives you access to multiple services e.g., social networking, gaming, news, live score and statistics, and digital wallet within the ReFSocial platform.
                                    </div>
                                </div>
                            </Accordion>

                            <Accordion
                                open={selectedAccord == 5 ? true : false}
                                heading={'What are the benefits & privileges of the Ref membership options?'}
                                toggle={() => this.toggleAccord(5)}
                            >
                                <div>
                                    <div className={'mb-3'}>Key benefits and privileges include:</div>
                                    <div>
                                        <b>Freemium </b>
                                    </div>
                                    <div>
                                        <div>Access to all services</div>
                                        <div>Join free Groups only</div>
                                        <div>Join free Channels only</div>
                                        <div>Join free Contests only </div>
                                    </div>
                                    <br />
                                    <div>
                                        <b>Premium </b>
                                    </div>
                                    <div>
                                        <div>Access to all services</div>
                                        <div>Skip Ads after 7secs</div>
                                        <div>Join free & paid Groups</div>
                                        <div>Join free & paid Channels </div>
                                        <div>Join all Contests</div>
                                    </div>
                                    <br />
                                    <div>
                                        <b>Premium+ </b>
                                    </div>
                                    <div>
                                        <div>Access to all services</div>
                                        <div>Skip Ads instantly</div>
                                        <div>Earn from watching ads</div>
                                        <div>Create/ Join free & paid Groups</div>
                                        <div>Create/ Join free & paid Channels</div>
                                        <div>Join all Contests</div>
                                        <div>Get Premium+ badge</div>
                                        <div>Get Verified badge</div>
                                    </div>
                                    <br />
                                    <div><span className={'join-fq'} onClick={() => this.goTo('refleague', `topage=dashboard/0`)}>Login In</span> to see the full benefits and privileges.</div>
                                </div>
                            </Accordion>

                            <Accordion
                                open={selectedAccord == 6 ? true : false}
                                heading={'How old should I be to join ReFSocial?'}
                                toggle={() => this.toggleAccord(6)}
                            >
                                <div className={'bdy'}>
                                    At least 18 years old
                                </div>
                            </Accordion>

                            <Accordion
                                open={selectedAccord == 7 ? true : false}
                                heading={'How can I navigate the services in ReFSocial?'}
                                toggle={() => this.toggleAccord(7)}
                            >
                                <div className={'bdy'}>
                                    Login & click the Grid button on the top left of the header to switch between services ReFSocial, ReFLeague, ReFLive & ReFPurse within the same platform
                                </div>
                            </Accordion>

                            <Accordion
                                open={selectedAccord == 8 ? true : false}
                                heading={'What are ReFLeague, ReFLive & ReFPurse about?'}
                                toggle={() => this.toggleAccord(8)}
                            >
                                <div className={'bdy'}>
                                    They are relevant services e.g., gaming, news, live score and statistics, digital wallet etc., that fans use frequently and can seamlessly access
                                    within the ReFSocial platform to enhance user experience.
                                </div>
                            </Accordion>

                            <Accordion
                                open={selectedAccord == 9 ? true : false}
                                heading={'What is the Global Series & how can I participate?'}
                                toggle={() => this.toggleAccord(9)}
                            >
                                <div className={'bdy'}>
                                    <div>
                                        Global series is a <b>Free-To-Play</b> annual fantasy-like gaming tournament where fans compete with other fans by answering questions to upcoming fixtures
                                    to score points for accuracy, to rank on the global leaderboard, earn bragging rights and win prizes.
                                    </div>
                                    <div>
                                        The <b>Global Champion</b> will lift the most coveted <b>Crystal Ball Trophy</b>.
                                    </div>
                                    <div>
                                        Simply <span className={'join-fq'} onClick={() => this.goTo('refleague', `topage=dashboard/0`)}>Login In</span> to <b>ReFSocial</b> and use the Grid button to switch 
                                        to ReFLeague where <b>Global Series</b> is the default tournament. The <b>Global Series</b> is open to all <b>ReFSocial</b> members.
                                    </div>
                                    <div><h4>Life is a game, you are the ref, take charge!</h4></div>
                                </div>
                            </Accordion>


                        </Container>


                    </section>


                </section>
            </React.Fragment >
        )
    }
}

export default Home;