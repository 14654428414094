import React from 'react';
import WSManager from "Helpers/WsManager";
import * as NC from "Constants/NetworkingConstants";
import { Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Images from 'Constants/Images';
import NavigationBar from './NavigationBar';
import {
    LoginModal, ForgotPasswordModal, SignUpModal, AlertModal, CompleteProfileModal, CongratsPopup,
    PaymentConfirmationModal, SecretAccessModal, ComingSoonModal
} from 'Modals';
import { notify } from "react-notify-toast";
import * as Constants from 'Constants/Constants'
import _ from 'lodash'
import { NavLink } from 'react-router-dom';
import Config from 'Config';

import { encode } from 'base-64';

var globalthis = null;


class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            LoginModalOpen: false,
            isModalFrom: "",
            showLoginModal: false,
            showForgotPassword: false,
            drawerShow: false,
            showSignupModal: false,
            showCompleteProfile: false,
            showCongratsModal: false,
            showPaymentConfirmModal: false,
            membershipID: "",
            membershipAmount: '',
            countineType: '',
            profile: WSManager.getProfile(),

            showSecretAccessModal: false,
            SecretAccessModalOpenFrom: "",
            showComingSoonModal: false,
            comingSoonModule: ""
        }
    }


    componentDidMount() {
        if (WSManager.loggedIn()) {
            Header.callGetMyProfileApi()
        }
    }




    static callGetMyProfileApi() {
        WSManager.Rest(NC.GET_MY_PROFILE).then(responseJson => {
            if (responseJson.response_code === NC.successCode) {
                globalthis.setState({
                    profile: responseJson.data.user_profile
                })
                WSManager.setProfile(responseJson.data.user_profile);
                WSManager.setProfileCompleted(responseJson.data.user_profile.is_profile_complete);

                if (responseJson.data.user_profile.secret_question == null) {
                    Header.secretAccessModalShow();
                }
                else if (responseJson.data.user_profile.is_profile_complete == 0) {
                    Header.CompleteProfileShow();
                }
            }
        });
    }


    static LoginModalShow = isModalFrom1 => {
        globalthis.setState({
            showLoginModal: true,
            isModalFrom: isModalFrom1
        });
    };

    LoginModalHide = () => {
        this.setState({
            showLoginModal: false
        });
    };


    static ForgotPasswordShow = isModalFrom => {
        globalthis.setState({
            showForgotPassword: true,
            isModalFrom: isModalFrom
        });
    };

    ForgotPasswordHide = () => {
        this.setState({
            showForgotPassword: false
        });
    };

    drawerToggle = () => {
        this.setState({ drawerShow: !this.state.drawerShow })
    }


    static SignupModalShow = isModalFrom => {
        globalthis.setState({
            showSignupModal: true,
            isModalFrom: isModalFrom
        });
    };

    SignupModalHide = () => {
        this.setState({
            showSignupModal: false
        });
    };

    static CompleteProfileShow = () => {
        globalthis.setState({
            showCompleteProfile: true
        });
    };

    CompleteProfileHide = () => {
        this.setState({
            showCompleteProfile: false
        });
    };



    logoutAlertShow = () => {
        Header.showAlertModal({
            message: "Are you sure you want to logout?",
            ModalAction: this.UserLogout
        }
        );
    }

    UserLogout = () => {

        WSManager.Rest(NC.GET_LOGOUT, { session_key: WSManager.getToken() }).then(res => {
            if (res.response_code === NC.successCode) {
                WSManager.logout();
                notify.show(Constants.Messages.logout_success, "success", 2500);
                setTimeout(() => {
                    window.location.assign("/");
                }, 500);
            }
        })

    };

    static showAlertModal = (AlertData) => {
        globalthis.setState({
            showAlertModal: true,
            AlertData: AlertData
        });
    };

    AlertModalHide = () => {
        this.setState({
            showAlertModal: false
        });
    };


    static CongratsModalShow = msg => {
        globalthis.setState({
            showCongratsModal: true,
            Congratsmsg: msg
        });
    };
    CongratsModalHide = () => {
        this.setState({
            showCongratsModal: false
        });
    };


    static showPaymentConfirmModal = (plan_id, planAmount, type) => {
        globalthis.setState({
            showPaymentConfirmModal: true,
            membershipID: plan_id,
            membershipAmount: planAmount,
            countineType: type
        });
    };

    PaymentConfirmModalHide = () => {
        this.setState({
            showPaymentConfirmModal: false
        });
    };


    BuyPlan = (planid, ActionType) => {
        this.setState({
            isLoading: true
        });
        WSManager.Rest(NC.PURCHASE_MEMBERSHIP, {
            plan_id: planid
        }).then(responseJson => {
            this.setState({
                isLoading: false
            });
            if (responseJson.response_code === NC.successCode) {
                Constants.SetUserBalance.callBalanceApi().then(isSuccess => { });
                let profile = WSManager.getProfile();
                profile["plan_id"] = responseJson.data.plan_id;
                profile["plan_round_id"] = responseJson.data.plan_round_id;
                WSManager.setProfile(profile);
                let msg = `You are now a ${responseJson.data.plan_id == 2 || responseJson.data.parent_plan_id == 2 ? "Premium" : "Premium+"
                    } member`;
                Header.CongratsModalShow(msg);
            }
        });
    }


    static showComingSoonModal(key) {
        globalthis.setState({
            showComingSoonModal: true,
            comingSoonModule: key
        });
    }


    goTo = (key) => {
        // For remove coming soon modal
        // if (!Config.isLOCAL) {
        //     if (key == "refsocial" || key == "refid") {
        //         Header.showComingSoonModal(key);
        //         return;
        //     }
        // }

        if (WSManager.loggedIn()) {

            WSManager.goToAppUrl(key);
        } else {
            Header.LoginModalShow(key)
        }
        this.drawerToggle()
    }

    goToMembership = (plan_id, idx) => {

        if (WSManager.loggedIn()) {
            this.props.history.push({ pathname: '/membership-plan', state: { idx, plan_id } })
        } else {
            Header.LoginModalShow()
        }

    }


    secretAccessModalHide = () => {
        this.setState({ showSecretAccessModal: false })
    }

    static secretAccessModalShow = (key) => {
        globalthis.setState({
            showSecretAccessModal: true,
            SecretAccessModalOpenFrom: key
        });
    };


    render() {

        globalthis = this;

        const {
            isModalFrom, showLoginModal, showForgotPassword,
            drawerShow, showSignupModal, showAlertModal, AlertData, profile, showCompleteProfile, Congratsmsg, showCongratsModal,
            showPaymentConfirmModal, countineType, membershipID, membershipAmount, showSecretAccessModal, SecretAccessModalOpenFrom,
            showComingSoonModal, comingSoonModule

        } = this.state;

        const { history } = this.props;

        const LoginModalProps = {
            history: history,
            isOpenFrom: isModalFrom,
            IsLoginModalShow: showLoginModal,
            IsLoginModalHide: this.LoginModalHide
        }

        const ForgotPasswordProps = {
            history: history,
            isOpenFrom: isModalFrom,
            IsForgotPasswordShow: showForgotPassword,
            IsForgotPasswordHide: this.ForgotPasswordHide
        }

        const SignUpModalProps = {
            history: history,
            isOpenFrom: isModalFrom,
            user_type: this.state.user_type,
            IsSignupModalShow: showSignupModal,
            IsSignupModalHide: this.SignupModalHide
        }

        const AlertModalProps = {
            history: history,
            IsAlertModalShow: showAlertModal,
            IsAlertModalHide: this.AlertModalHide,
            AlertData: AlertData
        }

        const CompleteProfileModalProps = {
            history: history,
            IsCompleteProfileShow: showCompleteProfile,
            IsCompleteProfileHide: this.CompleteProfileHide
        }

        const CongratsPopupProps = {
            history: history,
            IsCongratsModalShow: showCongratsModal,
            IsCongratsModalHide: this.CongratsModalHide,
            message: Congratsmsg
        }

        const PaymentConfirmModalProps = {
            history: history,
            IsPaymentModalShow: showPaymentConfirmModal,
            IsPaymentModalHide: this.PaymentConfirmModalHide,
            confirmAction: this.BuyPlan,
            countineType: countineType,
            plan_id: membershipID,
            amount: membershipAmount
        }

        const SecretAccessModalProps = {
            show: showSecretAccessModal,
            onHide: this.secretAccessModalHide,
            isFrom: SecretAccessModalOpenFrom
        }


        const ComingSoonModalProps = {
            show: showComingSoonModal,
            onHide: () => this.setState({ showComingSoonModal: false }),
            module: comingSoonModule
        }


        return (
            <React.Fragment>
                <header className={'app-header'}>
                    <Row>
                        <Col xs={4} xl={3} className={'nav-col'}><i onClick={this.drawerToggle} className="icon-nav-ic"></i></Col>
                        <Col xs={4} md={3} xl={3} className={'logo-col'}>
                            <div className={'logo-p'}><NavLink to={'/'}><img src={Images.LOGO_PURPLE} /></NavLink></div>
                        </Col>
                        <Col xs={6} md={6} xl={6} className={`navigation-col ${drawerShow ? 'show' : ''}`}>
                            <div className={'mid-prt'}>
                                <div className={'hd-part'}>
                                    <i className={'icon-close'} onClick={this.drawerToggle}></i>
                                    <span><img src={Images.LOGO_PURPLE} /></span>
                                </div>
                                <ul>
                                    {/* <li>
                                        <UncontrolledDropdown className={'user-drop'}>
                                            <DropdownToggle tag="span" caret>Membership</DropdownToggle>
                                            <DropdownMenu>

                                                {_.map(Constants.MembershipSubOptions, (item, idx) => {
                                                    return (

                                                        <DropdownItem
                                                            key={idx}
                                                            onClick={() => this.goToMembership(item.master_plan_id, idx)}>{item.plan_name}</DropdownItem>
                                                    );
                                                })}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </li> */}
                                    <li onClick={() => this.goTo('refsocial')}>ReFSocial</li>
                                    <li onClick={() => this.goTo('refid')}>ReFID</li>
                                    <li onClick={() => this.goTo('refleague')}>ReFLeague</li>
                                    <li onClick={() => this.goTo('reflive')}>ReFLive</li>
                                    <li onClick={() => this.goTo('refpay')}>ReFPurse</li>
                                </ul>
                            </div>
                        </Col>
                        <Col xs={4} md={3} xl={3} className={'drop-col'}>
                            <div className={'r-p'}>
                                {
                                    WSManager.loggedIn() ?
                                        <UncontrolledDropdown className={'user-drop'}>
                                            <DropdownToggle><span className={'drop-thumb'}><img src={profile.image != null && profile.image !== ""
                                                ? profile.image
                                                : Images.USER2
                                            } /></span></DropdownToggle>
                                            <DropdownMenu>
                                                {/* <DropdownItem onClick={()=>WSManager.goToAppUrl('refleague', `topage=profile`)}>My Profile</DropdownItem> */}
                                                <DropdownItem onClick={()=>WSManager.goToAppUrl('refsocial', `user-profile?id=${encode(this.state.profile.user_id)}`, true)}>My Profile</DropdownItem>
                                                
                                                <DropdownItem onClick={this.logoutAlertShow}>LogOut</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown> :
                                        <span onClick={() => Header.LoginModalShow()}><img src={Images.USER_IC} /></span>
                                }

                            </div>
                        </Col>
                    </Row>
                </header>
                {/* <NavigationBar /> */}


                {showLoginModal && (
                    <LoginModal
                        {...LoginModalProps}
                    />
                )}

                {showForgotPassword && (
                    <ForgotPasswordModal
                        {...ForgotPasswordProps}
                    />
                )}


                {showSignupModal && (
                    <SignUpModal
                        {...SignUpModalProps}
                    />
                )}

                {showAlertModal && (
                    <AlertModal
                        {...AlertModalProps}
                    />
                )}

                {showCompleteProfile && (
                    <CompleteProfileModal
                        {...CompleteProfileModalProps}
                    />
                )}

                {showCongratsModal && (
                    <CongratsPopup
                        {...CongratsPopupProps}

                    />
                )}


                {showPaymentConfirmModal && (
                    <PaymentConfirmationModal
                        history={this.props.history}
                        IsPaymentModalShow={showPaymentConfirmModal}
                        IsPaymentModalHide={this.PaymentConfirmModalHide}
                        confirmAction={this.BuyPlan.bind(this)}
                        countineType={this.state.countineType}
                        plan_id={this.state.membershipID}
                        amount={this.state.membershipAmount}
                    />
                )}


                {
                    showSecretAccessModal && <SecretAccessModal {...SecretAccessModalProps} />
                }

                {
                    ComingSoonModal && <ComingSoonModal {...ComingSoonModalProps} />
                }


                {
                    !WSManager.loggedIn() &&
                    <div className={'floating-nav'}>
                        <ul>
                            <li><span onClick={() => Header.LoginModalShow()}>Log in</span></li>
                            <li><span onClick={() => Header.SignupModalShow()}>Sign up</span></li>
                        </ul>
                    </div>

                }
            </React.Fragment>
        )
    }
}

export default Header;