import React from 'react';
import WSManager from "Helpers/WsManager";
import * as NC from "Constants/NetworkingConstants";
import { Row, Col } from 'reactstrap';
import Images from 'Constants/Images';
import Config from 'Config';
import {Header} from './index';

class NavigationBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
    }


    goTo = (key) => {
        if (WSManager.loggedIn()) {
            WSManager.goToAppUrl(key);
        }else{
            Header.LoginModalShow(key)
        }
    }

    render() {
        return (
            <React.Fragment>
                <nav className={'navigation-bar'}>

                    <Row className={'justify-content-center'}>
                        <Col xl={8} lg={10} xs={12}>

                            <Row>
                                <Col xs={3} md={3}>
                                    <div className={'navie'} onClick={() => this.goTo('refsocial')}>
                                        <img src={Images.REFSOCIAL_B} />
                                        <div>ReFSocial</div>
                                    </div>
                                </Col>
                                <Col xs={3} md={3}>
                                    <div className={'navie'} onClick={() => this.goTo('refleague')}>
                                        <img src={Images.REFPREDICTOR_B} />
                                        <div>ReFLeague</div>
                                    </div>
                                </Col>
                                <Col xs={3} md={3}>
                                    <div className={'navie'} onClick={() => this.goTo('reflive')}>
                                        <img src={Images.REFLIVE_B} />
                                        <div>ReFLive</div>
                                    </div>
                                </Col>
                                <Col xs={3} md={3}>
                                    <div className={'navie'} onClick={() => this.goTo('refpay')}>
                                        <img src={Images.REFPAY_B} />
                                        <div>ReFPay</div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </nav>
            </React.Fragment>
        )
    }
}

export default NavigationBar;