import React from "react";
// import { MyContext } from "../context";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Header, Loader, NoDataScreen, SubHeader } from "../Components";
import WSManager from "../Helpers/WsManager";
import * as NC from "../Constants/NetworkingConstants";
import InfiniteScroll from "react-infinite-scroll-component";
import Moment from "react-moment";
import MetaData from "../Helpers/MetaData";
import UtilityFunctions from "../Helpers/UtilityFunctions";
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import _ from "lodash";
import renderHTML from 'react-render-html';
import Slider from "react-slick";

import { encode } from "base-64";

import * as Constants from "../Constants/Constants";
import Images from "../Constants/Images";

const iconList = {
  "1": "icon-trophy-ic",
  "2": "icon-trophy-ic",
  "3": "icon-trophy-ic",
  "4": "icon-Wallet",
  "5": "icon-inbox",
  "6": "icon-Wallet",
  "7": "icon-Wallet",
  "8": "icon-trophy-ic",
  "9": "icon-Wallet",
  "10": "icon-logo",
  "11": "icon-switch-team",
  "12": "icon-logo",
  "13": "icon-logo",
  "14": "icon-logo",
  "15": "icon-logo",
  "16": "icon-logo",
  "17": "icon-logo",
  "18": "icon-logo",
  "19": "icon-logo",
  "20": "icon-trophy-ic",
  "21": "icon-logo",
  "22": "icon-trophy-ic",
  "23": "icon-Wallet",
  "24": "icon-Wallet",
  "25": "icon-Wallet",
  "26": "icon-Wallet",
  "27": "icon-Wallet",
  "28": "icon-Wallet",
  "29": "icon-trophy-ic",
  "30": "icon-user-select",
  "31": "icon-logo",
  "32": "icon-logo",
  "33": "icon-Wallet",
  "34": "icon-Wallet",
  "35": "icon-Wallet",
  "36": "icon-Wallet",
  "37": "icon-Wallet",
  "38": "icon-Wallet",
  "39": "icon-trophy-ic",
  "40": "icon-trophy-ic",
  "56": "icon-like1",
  "57": "icon-comment-fill",
  "58": "icon-comment-fill",
  "130": "icon-Wallet",
  "131": "icon-Wallet",
  "132": "icon-logo",
  "133": "icon-logo",
  // "145": "icon-bell",
  "145": "icon-notification-bell",
  "146": "icon-add-user", //add People Request
  "148": "icon-repost" //add People Request
};
var hasMore = false;

export default class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      notificationList: [],
      limit: 20,
      sort_field: "added_date",
      sort_order: "DESC",
      total: 0,
      offset: 0,
      showSearch: false,
      pageBanner: ""
    };
  }

  componentDidMount() {
    WSManager.setNotificationCount(0);
    this.getUserNotifications();
    this.getPageBanner();
  }

  gotoDashboard = () => {
    this.props.history.push({
      pathname: "/feeds"
    });
  };

  goToPrivateContest = () => {
    this.props.history.push({
      pathname: "/private-contest"
    });
  };


  goToContest = (itm) => {
    let content = JSON.parse(itm.content);
    WSManager.goToAppUrl('refleague', `topage=contest/detail/${content.contest_unique_id}`)
  };

  getUserNotifications() {
    let param = {
      offset: this.state.offset,
      sort_field: this.state.sort_field,
      limit: this.state.limit,
      sort_order: this.state.sort_order
    };
    if (this.state.notificationList.length === 0) {
      this.setState({ isLoading: true });
    }
    WSManager.Rest(NC.GET_USER_NOTIFICAITONS, param).then(
      responseJson => {
        this.setState({ isLoading: false });

        console.log('responseJson====>', responseJson);

        if (responseJson.response_code === NC.successCode) {
          if (typeof responseJson.data != "undefined") {
            hasMore = responseJson.data.is_load_more;
            this.setState({
              offset: responseJson.data.offset,
              total: responseJson.data.total
            });
            if (this.state.offset > 0) {
              this.setState({
                notificationList: [
                  ...this.state.notificationList,
                  ...responseJson.data.notification_list
                ]
              });
            } else {
              this.setState({
                notificationList: responseJson.data.notification_list
              });
            }
          }
        }
      }
    );
  }

  fetchMoreData = () => {
    this.getUserNotifications();
  };

  _renderTagMessage = rowData => {
    var msg = rowData.message;
    var content = JSON.parse(rowData.content);
    _.map(Object.keys(content), (key, idx) => {
      msg = msg.replace("{{" + key + "}}", content[key]);
    });
    return msg;
  };

  renderNotification = (notificationItem, notificationKey) => {
    return (
      <div onClick = {() => this.goToNotificationDetails(notificationItem, notificationKey)} key={notificationKey} className="list-card" style = {{cursor: "pointer"}}>
        <i
          className={
            (iconList[notificationItem.notification_type || "1"] || "icon-inbox")
            +
            ((notificationItem.notification_type == 56 ||
              notificationItem.notification_type == 57 ||
              notificationItem.notification_type == 58 ||
              notificationItem.notification_type == 145 ||
              notificationItem.notification_type == 148 ) ? ' reflive-clr' : '')
          }
        />
        {/* notification_type == 7 //Debit */}
        {/* notification_type == 6 //Credit */}
        {
          (notificationItem.notification_type == 6 ||
          notificationItem.notification_type == 7)
          ?
            <div Style = {'display: flex; flex: 1;align-items: baseline;'}>
                <div>
                  <p>{renderHTML(this._renderTagMessage(notificationItem))}</p>
                  <p className="notification-timing">
                  <Moment
                    date={UtilityFunctions.getUtcToLocal(notificationItem.added_date)}
                    format="DD MMM - hh:mm A "
                  />
                  {notificationItem.notification_type == 8 && (
                    <a
                      href
                      className="pull-right"
                      onClick={() => this.goToContest(notificationItem)}
                    >
                      Go To Private Contest
                    </a>
                  )}
                </p>
                </div>
                <div Style = {'display: flex; flex: 1; flex-direction: column; justify-content: center; align-items: flex-end; '+(notificationItem.notification_type == 6 ? 'color: green;' : 'color: red;')} className="notify-bal-wrapper">
                  <span class="icon-Rits " style = {{display: 'flex', flex: 1, marginBottom: 5 , fontSize: 16}}>{UtilityFunctions.abbreviateNumber(JSON.parse(notificationItem.content).amount)}
                  <span className={'credit-debit-warpper '+(notificationItem.notification_type == 6 ? 'c' : 'd')}>

                  </span>
                  </span>
                  {
                    JSON.parse(notificationItem.content).user_total_amount
                    ?
                    <span Style = {'display: flex; flex: 1; justify-content: center;align-items: center;font-size:13px'}>
                      <span style={{marginRight: '5px', color: '#808080'}}>Bal. </span>
                      <span class="icon-Rits" style = {{display: 'flex', flex: 1}}>{UtilityFunctions.abbreviateNumber(JSON.parse(notificationItem.content).user_total_amount)}</span>
                    </span>
                    :
                      null
                  }
                </div>
            </div>
          :
            <p>{renderHTML(this._renderTagMessage(notificationItem))}</p>
            
        }
        {/* <p>{renderHTML(this._renderTagMessage(notificationItem))}</p> */}
        {/* <p className="notification-timing">
          <Moment
            date={UtilityFunctions.getUtcToLocal(notificationItem.added_date)}
            format="DD MMM - hh:mm A "
          />
          {notificationItem.notification_type == 8 && (
            <a
              href
              className="pull-right"
              onClick={() => this.goToContest(notificationItem)}
            >
              Go To Private Contest
            </a>
          )}
        </p> */}
      </div>
    );
  };

  isProfileOrFeedNotification = (notificationItem, notificationKey) => {
    if (notificationItem.notification_type == '56' || 
      notificationItem.notification_type == '57' ||
      notificationItem.notification_type == '148'
    )
    {
      return 'Feed'
    }
    else if (notificationItem.notification_type == '145' || 
      notificationItem.notification_type == '146' ||
      notificationItem.notification_type == '30'
    ) 
    {
      return 'Profile'
    }

    return false
  }

  goToNotificationDetails = (notificationItem, notificationKey) => {

    if (this.isProfileOrFeedNotification(notificationItem, notificationKey) == 'Feed')
    {
      this.goToPostDetail({activity_id: notificationItem.source_id})
    }
    else if (this.isProfileOrFeedNotification(notificationItem, notificationKey) == 'Profile')
    {
      this.props.history.push(`/user-profile?id=${encode(notificationItem.source_id)}`)
      // WSManager.goToAppUrl('refsocial', `user-profile?id=${encode(notificationItem.user_id)}`, true) 
    }
  }

  goToPostDetail = (feedItem) =>{
    this.props.history.push({
        pathname:'/post-detail', state:{feedItem:feedItem}
    })
  }
  // goToUserProfile = (user_id) =>{
  //   this.props.history.push({
  //       pathname:'/post-detail', state:{feedItem:feedItem}
  //   })
  // }


  getPageBanner = () => {

    let param = {
      page_type_id: "5",
      position_id: "1"
    }
    WSManager.Rest(NC.GET_PAGE_BANNER, param)
      .then(res => {
        if (res.response_code === NC.successCode) {
          this.setState({ pageBanner: res.data })
        }
      })
  }


  render() {
    const { notificationList, isLoading, showSearch, pageBanner } = this.state;
    const HeaderOption = {
      menu: false,
      Logo: true,
      Notification:false,
      back:true
    };

    return (
      
          <div className="web-container notification-container">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.notification.title}</title>
              <meta
                name="description"
                content={MetaData.notification.description}
              />
              <meta name="keywords" content={MetaData.notification.keywords} />
            </Helmet>
            <Header {...this.props} HeaderOption={HeaderOption} showNavigation = {false} />

            <div className={'notification-header'} >
              <div className={'nt-row'}>
                <span className={'back-ic'} onClick={() => this.props.history.goBack()}><i className="icon-left-arrow" /></span>
                <span className={'title-lb'}>Notifications</span>
                <span className={'search-area'}>
                  <i className={'icon-search'} onClick={() => this.setState({ showSearch: !showSearch })}></i>{
                    showSearch && <div className={'search-box'}>
                      <input type={'text'} placeholder={'Search...'} />
                    </div>
                  }

                </span>
                {/* <span className={'noti-opts'}>
                  <UncontrolledDropdown direction={'left'} className={'act-dropdown'}>
                    <DropdownToggle> <i className={'icon-toggle-vertical'}></i></DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem></DropdownItem>
                      <DropdownItem>Another Action</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </span> */}
              </div>
            </div>


            {isLoading && <Loader />}


            {
              (pageBanner && pageBanner.length > 0) &&
              <div className={'lb-ban-wrap'}>
                <Slider {...Constants.BANNER_SLIDER_SETTINGS}>
                  {pageBanner.map((itm, i) => {
                    return <div key={i} className={'lb-banner'}>
                      <img src={itm.image} />
                    </div>
                  })}
                </Slider>
              </div>
            }

            <div>
              {/* <Row>
                <Col sm={12}>
                  <SubHeader
                    Title="Notification"
                    ImgShown={Images.HEADER_BG}
                    BannerImage=""
                    AddClassName="single-title-header"
                  />
                </Col>
              </Row> */}
              {
                 notificationList.length > 0
                ?
                  <InfiniteScroll
                    dataLength={notificationList.length}
                    next={this.fetchMoreData.bind(this)}
                    hasMore={hasMore}
                    loader={
                      hasMore && (
                        <h4 className="text-center font-12 m-t-10">Loading...</h4>
                      )
                    }
                  >
                    {
                      <div
                        id="notification-scroll-list"
                        className="notification-list"
                      >
                        {notificationList.length > 0 &&
                          notificationList.map((item, index) => {
                            return this.renderNotification(item, index);
                          })}
                      </div>
                    }
                  </InfiniteScroll>
                :
                  (
                  <NoDataScreen
                    Content={{
                      ImgShown: Images.BIG_NOTIFICATION_BELL,
                      IsButton: true,
                      ButtonText: "REFSOCIAL",
                      Title: "You don’t have any",
                      NoDataContent: "Notifications yet!",
                      BtnAction: this.gotoDashboard,
                      WithFilter: ""
                    }}
                  />
                )
              }
              
            </div>
          </div>
        
    );
  }
}
