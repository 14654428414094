import React from "react";
import { Row, Col, Button, Modal, FormGroup } from "react-bootstrap";
import { Header, Loader, RKFloatingLabelInput } from "Components";
import { notify } from "react-notify-toast";
import Validation from "Helpers/validation";
import "react-phone-number-input/style.css";
import WSManager from "Helpers/WsManager";
import * as NC from "Constants/NetworkingConstants";
import Select from "react-select";
import Images from 'Constants/Images';

export default class ForgotPassword extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      phoneNo: "",
      submitClick: false,
      isLoading: false,
      posting: false,
      country_list: [],
      PhoneCode: null
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    WSManager.Rest(NC.GET_ALL_COUNTRY, {}).then(responseJson => {
      if (responseJson.response_code === 200) {
        this.setState({
          country_list: responseJson.data.country_list
        })
      }
    })
  }

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  IsFormValid() {
    const { phoneNo, PhoneCode } = this.state;
    var isValid = true;

    if (
      Validation.validate("required", phoneNo) != "success" ||
      Validation.validate("phone_no", phoneNo) != "success"
    ) {
      isValid = false;
    }
    else if (PhoneCode === "" || PhoneCode === null) {
      isValid = false;
    }
    return isValid;
  }


  selectChange = (val, key) => {
    this.setState({
      [key]: val,
      [`selected${key}`]: val
    })
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({
      submitClick: true
    });
    if (this.IsFormValid()) {
      this.setState({ isLoading: true, posting: true });
      let param = {
        phone_no: this.state.phoneNo,
        phone_code: this.state.PhoneCode.phonecode
      };

      WSManager.Rest(NC.FORGOT_PASSWORD, param).then(
        responseJson => {
          this.setState({ isLoading: false, posting: false });
          if (responseJson.response_code === NC.successCode) {
            if (responseJson && responseJson.response_code === NC.successCode) {
              notify.show(responseJson.message, "success", 5000);
              this.props.IsForgotPasswordHide();
            }
          }
        }
      );
    }
  }

  render() {
    const { IsForgotPasswordShow, IsForgotPasswordHide } = this.props;
    const { phoneNo, submitClick, isLoading, posting, PhoneCode, country_list } = this.state;
    return (


      <Modal
        show={IsForgotPasswordShow}
        onHide={IsForgotPasswordHide}
        dialogClassName="custom-modal-with-bg"
      >
        {isLoading && <Loader />}
        <div className="modal-header-bg">
          <a
            
            className="modal-close-btn"
            onClick={IsForgotPasswordHide}
          >
            <i className="icon-close" />
          </a>
          <img src={Images.LOGO_BLACK} alt="" />
          <div className="modal-title fr-d-fn">For the fans</div>
        </div>
        <div className="modal-overlay" />

        <form className="form-section" onSubmit={this.onSubmit}>
          <Modal.Body>
            <div className="modal-txt">
              Just enter the mobile number used when registering your
              account and we will send a new password. If it doesn’t appear
              in a few moments, then try again.
                </div>

            <div className="verification-block">
              <Row>
                <Col xs={12}>
                  <FormGroup
                    className={
                      "input-label-center input-transparent font-14 " +
                      (submitClick
                        ? PhoneCode !== "select" && PhoneCode !== "" && PhoneCode !== null
                          ? "success"
                          : "has-error"
                        : "")
                    }
                  >
                    <div className="select-gender">
                      {/* <label style={formInputStyle.label}>Country Code</label> */}
                      <div className="genderStyle">
                        <Select
                          onChange={e => this.selectChange(e, 'PhoneCode')}
                          options={country_list}
                          classNamePrefix="secondary"
                          className="select-secondary minusML10"
                          placeholder="Select Country Code"
                          isSearchable={true}
                          isClearable={false}
                          value={PhoneCode}
                          getOptionLabel={(option) => `${option.phonecode} (${option.country_name})`}
                          getOptionValue={(option) => option.phonecode}
                          theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary: "#023320"
                            }
                          })}
                        />
                      </div>
                      <span className="select-arr">
                        <i className="icon-arrow-sort" />
                      </span>
                      <div className="gender-border col-sm-12" />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="verification-block">

              <Row>
                <Col xs={12}>
                  <FormGroup
                    className={`input-label-center ${submitClick ?
                      Validation.validate("required", phoneNo) == "success" && Validation.validate("phone_no", phoneNo) == "success" ?
                        'has-success' : 'has-error'
                      : ''
                      }`
                    }

                  >
                    <RKFloatingLabelInput
                      className="rkfloatingLabelInput"
                      id="phoneno"
                      autocomplete="off"
                      type="text"
                      isNumberOnly={true}
                      name="phoneNo"
                      value={phoneNo}
                      placeholder="Enter Mobile Number"
                      onChange={this.onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>

            <div className={'wp-mod-sh'}>
              <span className={'ic-grp'}>
                <span>
                  <img src={Images.TEXTMSG_IMG} />
                </span>
                <span>
                  <img src={Images.WHATSAPP_IMG} />
                </span>
              </span>
            </div>

            <div className="text-center">
              <Button
                className="btn btn-rounded btn-primary "
                onClick={this.onSubmit}
                type="submit"
                disabled={posting || !phoneNo}
              >
                Send
                  </Button>
            </div>
            <div className="text-center user-help">
              New to ReFPredictor League?{" "}
              <a
                
                onClick={() => {
                  Header.SignupModalShow("");
                  IsForgotPasswordHide();
                }}
              >
                Sign Up
                  </a>
            </div>
          </Modal.Body>
        </form>
      </Modal>
    );
  }
}
